/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React from 'react';

import PropTypes from 'prop-types';

import { Route, Redirect } from 'react-router-dom';

import DefaultLayout from '../pages/_layouts/default';

import InitialLayout from '../pages/_layouts/Initial';

import { store } from '../store';

import { Error400and500 } from './components/error';

export default function RouteWrapper({
  component: Component,
  initial,
  isPrivate,
  ...rest
}) {

  const { signed } = store.getState().configuration.data;

  // const signed = true

  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }

  const Layout = initial? InitialLayout : DefaultLayout;

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Error400and500 {...props} />
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]).isRequired,
};

RouteWrapper.defaultProps = {};
