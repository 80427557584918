import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify'

import { useDispatch, useSelector } from 'react-redux';

import { Form, Input, Textarea } from '@rocketseat/unform';

import { Container } from './styles';

import { setOccurrence } from '~/store/modules/occurrence/actions'

import { setValidation } from '~/store/modules/validButton/action';

function StepFourth() {
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.occurrence)
  const [isAuto, setIsAuto] = useState(false)
  const validButton = useSelector(state => state.validButtonState) 
  const configuration = useSelector(state => state.configuration.data);
  console.log('config', configuration, data)

  const[customTitle, setCustomTitle] = useState('Título')
  const[customDescription, setCustomDescription] = useState('Descrição')
  

  useEffect(() => {
    if(data && data.automatic /*&& data.title*/){
      setIsAuto(true)
    }

    if(data){
      let currentMotivo = configuration.motivos.filter(
        (value) => {
          return value.id === parseInt(data.motivo)
        }
      ) [0]

      if(currentMotivo.customFieldsEnable){
        if(currentMotivo.customFieldsNames[0]){
        setCustomTitle(currentMotivo.customFieldsNames[0])
        }
        if(currentMotivo.customFieldsNames[1]){
          setCustomDescription(currentMotivo.customFieldsNames[1])
          }
      }
    }

  }, data)

  useEffect(() => {        
    dispatch(setValidation({
      ...validButton.data, 
      step4: {
        ...validButton.data.step4,
        valid: (data?.title?.length > 4 && data?.description?.length > 9)
      }
    }))        
  }, [data.title, data.description])  
  console.log('auto', isAuto)
  return (
    <Container>
      <Form onSubmit={() => {}}>
        <div>
          <label className="data-cy-qrcode-report-title-describe">{
            `${
                !isAuto 
                ? 
                  `Informe os campos ${customTitle.toLowerCase()} e ${customDescription.toLowerCase()} ${
                    data.type == 'INCIDENTE'
                     ? 
                      'do incidente'
                     : 
                      data.type == 'SOLICITAÇÃO'
                      ?
                         'da solicitação'
                      : 
                          'do acionamento'
                    }:` 
                :
                     `Informe o campo ${customDescription.toLowerCase()} ${data.type == 'INCIDENTE' ? 'do incidente' : data.type == 'SOLICITAÇÃO' ? 'da solicitação' : 'do acionamento'}:`} `}</label>
          {!isAuto && <Input
            className="data-cy-qrcode-report-title"
            type="text"
            name="title"
            placeholder= {customTitle}
            autoComplete="off"
            value={data.title}
            onBlur={(e) => {
              if(e.target.value.length < 5) {
                toast.error('O campo '+customTitle.toLowerCase()+' precisa ter no mínimo 5 caracteres!', {className: 'data-cy-qrcode-error-title-5-characters'})
              }
            }}
            onChange={e => dispatch(setOccurrence({...data, title: e.target.value}))}
          />}
        </div>
        <div>
          <Textarea
            className="data-cy-qrcode-report-description"
            type="text"
            autoComplete="off"
            name="description"
            placeholder={customDescription}
            value={data.description}
            rows={4}
            onBlur={(e) => {
              if(e.target.value.length < 10) {
                toast.error('O campo '+customDescription.toLowerCase()+' precisa ter no mínimo 10 caracteres!', {className: 'data-cy-qrcode-error-description-10-characters'})
              }
            }}            
            onChange={e => dispatch(setOccurrence({...data, description: e.target.value}))}
          />
        </div>
      </Form>
    </Container>
  );
}

export default StepFourth;
