import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100%;
  display: flex;
  width: 100%;
  padding: 10px;
  box-sizing: border-box !important;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  min-height: 100%;
  background: #ffffffc9;
  border-radius: 4px;
  box-shadow: 0px 0px 4px 0px #00000024;
  flex-direction: column;
  padding: 10px 10px;
  justify-content: space-between;
  width: 60%;

  @media only screen and (max-width: 800px) {
    width: 100%;
  }

  > .content {
    > .title {
      font-size: 18px;
      font-weight: 600;
      color: #4fafbd;
      text-transform: uppercase;
      padding: 12px;
      text-align: center;
    }

    > div:nth-child(2) {
      margin-top: 12px;
    }

    > * + * {
      margin-top: 8px;
    }

    > .teste {
      width: 100%;
      height: 500px;
    }
  }

  > div.buttons-bottom-container {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    justify-content: space-between;
    padding: 0px 0px;

    > button, a {
      border: none;
      border-radius: 50px;
      padding: 16px 0px;
      font-size: 16px;
      background: linear-gradient(90deg, rgba(104,153,255,1) 35%, rgba(160,96,187,1) 100%); 
      color: #fff;
      opacity: 1;
      width: 100%;
      text-align: center;

      &:disabled {
        opacity: 0.6;
      }

      &:active {
        transition: 0.2s;
        opacity: 0.6;
      }
    }
  }
`;

export const Card = styled.div`
  box-shadow: 0px 1px 4px 1px #00000033;
  display: flex;
  background: #fff;
  border-radius: 4px;
`;

export const CardItemLeft = styled.div`
  width: ${({ w }) => w?w:'auto'};
  flex-grow: ${({ w }) => w?'initial':1};
  flex-direction: column;
  justify-content: center;
  border-right: 3px solid;
  border-color: #4EBC7A;
  height: 100px;
  padding: 10px 20px;
  display: flex;
  align-items: center;

  > span {
    color: #9E9E9E;
    font-size: 24px;
    font-weight: bold;
    display: block;
  }
`;

export const CardItemRight = styled.div`
  padding: 10px 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div:nth-child(1) {
    display: flex;
    justify-content: space-between;
    display: flex;
    align-items: center;

    > div:nth-child(1) {
      > span {
        font-size: 12px;
      }
    }

    > div:nth-child(2) {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      height: 100%;

      > button {
        display: flex;
        border: none;
        background: transparent;
   
        &:active {
          transition: 0.2s;
          opacity: 0.6;
        }
      }
    }

  }
  > div:nth-child(2) {
    display: flex;
    justify-content: space-between;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    margin-top: 4px;
    display: flex;
    align-items: center;
  }
  > div:nth-child(3) {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    align-items: center;
    padding-top: 12px;

    > svg {
      margin-right: 2px;
    }
  }
`;
export const OpenStatus = styled.span`
  color: red;
  font-weight: normal;
`;

export const OngoingStatus = styled.span`
  color: #ffc107;
  font-weight: normal;
`;

export const CancelledStatus = styled.span`
  color: #dc3545;
  font-weight: normal;
`;

export const FinishedStatus = styled.span`
  color: #007bff;
  font-weight: normal;
`;

export const PendentStatus = styled.span`
  color: #6c757d;
  font-weight: normal;
`;

export const ForwardedStatus = styled.span`
  color: blue;
  font-weight: normal;
`;

export const RefusedStatus = styled.span`
  color: black;
  font-weight: normal;
`;

export const OtherStatus = styled.span`
  color: black;
  font-weight: normal;
`;

export const ReopenedStatus = styled.span`
  color: #c617c6;
  font-weight: normal;
`;