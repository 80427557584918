import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 20px;
  height: 200px;

  @media only screen and (max-width: 800px) {
    height: 150px;
  }

  .file {
    height: 100%;
    max-height: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: #fff;

    > button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: #c75353d4;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
    }

    > img {
      width: auto !important;
      height: 100% !important;
    }

    @media only screen and (max-width: 800px) {
      height: 150px;
    }
  }

  .owl-item:first-child {
    border-radius: 0.4285rem 0px 0px 0.4285rem;
    overflow: hidden;
  }
  .owl-item:last-child {
    border-radius: 0 0.4285rem 0.4285rem 0;
    overflow: hidden;
  }

  .owl-carousel {
    height: 100%;
  }

  .owl-carousel .owl-stage-outer {
    z-index: 1;
  }

  .owl-nav {
    position: absolute;
    top: 40%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0px 12px;

    > button {
      box-shadow: none;
      color: #999 !important;
      font-size: 50px !important;
      z-index: 1;

      @media only screen and (max-width: 800px) {
        font-size: 30px !important;
      }
    }
  }

  .owl-dots {
    position: absolute;
    bottom: 0%;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 12px 0px;
    z-index: 1;

    .active {
      background: #fff;
    }

    > button {
      width: 8px;
      height: 8px;
      background: #ccc;
      border-radius: 50%;

      @media only screen and (max-width: 800px) {
        width: 6px;
        height: 6px;
      }
    }
    > button + button {
      margin-left: 6px;
    }
  }
`

export const NotImage = styled.div `
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  max-height: 100%;
  height: 200px;

  > img {
    max-width: 100%;
    max-height: 100%;
  }
`
