import produce from 'immer';

const INITIAL_STATE = {
  data: {
    motivo: '',
    dangerousness: null,
    name: '',
    phone: '',
    validPhone: true,
    code: null,
    title: '',
    description: '',
    image: [],
    token: null,
    qr_code_user_id: null,
    unitId: null,
    localId: null,
    protocol: null,
    id: null,
    automatic: false,
    type: 'ACIONAMENTO',
    anonymous: false,
  }
};

export default function occurrence(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@occurrence/CREATE_OCCURRENCE': {
        draft.data = action.payload.data;        
        break;
      }
      case '@occurrence/RESET_OCCURRENCE': {
        draft.data = INITIAL_STATE.data;
        break;
      }
      case '@occurrence/REGISTER_OCCURRENCE_FAILURE': {
        draft.data.loading = false;
        break;
      }
      default:
    }
  });
}
