/* eslint-disable eqeqeq */
import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Form } from '@rocketseat/unform';

import {
  FaThermometerQuarter,
  FaThermometerHalf,
  FaThermometerFull
} from 'react-icons/fa';

import { setOccurrence } from '~/store/modules/occurrence/actions'

import { setValidation, resetValidation } from '~/store/modules/validButton/action';

import { Container, Content } from './styles';

function StepOne() {
  const dispatch = useDispatch();
  const occurrence = useSelector(state => state.occurrence)
  const validButton = useSelector(state => state.validButtonState) 
  const { data: configuration } = useSelector(state => state.configuration)
  const { data } = useSelector(state => state.occurrence);

  useEffect(() => {
    dispatch(resetValidation())
    let dangerousnessValid = data.dangerousness || data.dangerousness === 0

    dispatch(setValidation({
      ...validButton.data, 
      step1: {
        ...validButton.data.step1,
        valid: !!(data.motivo && dangerousnessValid)
      }
    }))    
  }, [data.motivo])

  function changedOccurrence(e) {
    const {
      dangerousness,
      incidentTypeUnit,
      photoEnforced,
      photoEnforcedType,
      automatic,
      incidentTypeAutomatic, 
      anonymous,
      type
    } = getMotivos()
    .filter(res => res.id === parseInt(e.target.value))[0];

    console.log('occurrence', occurrence.data)
    console.log('occurrence 1', incidentTypeUnit[0], automatic)

    dispatch(setOccurrence({
      ...occurrence.data, 
      ...incidentTypeUnit[0],
      localId: configuration.localId,
      motivo: e.target.value,
      dangerousness,
      photoEnforced,
      photoEnforcedType,
      automatic,
      title: getValueIfExists(incidentTypeAutomatic, 'incidentTitle'),
      description: getValueIfExists(incidentTypeAutomatic, 'incidentDescription'),
      fixedDescription: getValueIfExists(incidentTypeAutomatic, 'fixedDescription'),
      type,
      anonymous,      
    }))
  }

  function getValueIfExists (obj, index) {
    return obj && Array.isArray(obj) && obj.length > 0 ?  obj[0][index] : ''
  }

  function getMotivos() {
    // configuration.motivos

    let motivos = configuration.motivos
    console.log('2aqui', motivos)
    // let motivos = JSON.parse(JSON.stringify(configuration.motivos))
    // for (let i in motivos) {
    //   if(motivos[i].id == 933){
    //     motivos[i].type = 'INCIDENTE'
    //     motivos[i].incidentTypeAutomatic[0].fixedDescription = true
    //     motivos[i].photoEnforcedType = ''

    //   }
    // }
    
    return motivos
  }    
    
  return (  
    <Container>
      <Form onSubmit={() => {}}>
        <div>
          <label className="data-cy-qrcode-choose-motive-input">Escolha o motivo:</label>    
          <div className="select">
            <select
              className="data-cy-qrcode-choose-motive"
              type="text"
              name="motivo"
              // placeholder={`Motivo ${occurrence.data.type =='INCIDENTE' ? 'do incidente' : occurrence.data.type == 'SOLICITAÇÃO' ? 'da solicitação' : 'do acionamento'}`}
              placeholder="Motivo do acionamento"
              value={occurrence.data.motivo}
              onChange={e => changedOccurrence(e)}
            >
              <option value="" disabled selected>
              Motivo do acionamento
              {/* {`Motivo ${occurrence.data.type =='INCIDENTE' ? 'do incidente' : occurrence.data.type == 'SOLICITAÇÃO' ? 'da solicitação' : 'do acionamento'}`} */}
              </option>
              {getMotivos().map(res => {
                return (
                  <option 
                    value={res.id}
                    style={{
                      color: res.specific?'#888': '#000'
                    }}
                  >
                    {res.title}
                  </option>
                )
              })}
            </select>
            {/* <Select
              type="text"
              name="motivo"
              placeholder="Motivo da ocorrência"
              value={occurrence.data.motivo}
              options={configuration.motivos}
            /> */}
          </div>
        </div>
        <Content>
          <label className="data-cy-qrcode-criticality-level" >Nível de criticidade:</label>
          <div className="level-of-periculosidade">
            <button 
              disabled={false}
              onClick={() => {}}
            >
              <div style={{alignItems: 'center'}}>
                <FaThermometerQuarter 
                  className={occurrence.data.dangerousness  == 0? 'data-cy-qrcode-print-level-low-active' : 'data-cy-qrcode-print-level-low-disable'}
                  color={occurrence.data.dangerousness == 0 ? '#38bbca' : '#adadad'}
                  size={40}
                />
                <label 
                  style={{
                    fontSize: 20, 
                    color: occurrence.data.dangerousness == 0 ? '#38bbca' : '#adadad'
                  }}
                >
                  Baixa
                </label>
              </div>
            </button>
            <button 
              disabled={false} 
              onClick={() => {}}
            >
              <div style={{alignItems: 'center'}}>
                <FaThermometerHalf 
                  className={occurrence.data.dangerousness  == 1? 'data-cy-qrcode-print-level-mid-active' : 'data-cy-qrcode-print-level-mid-disable'}
                  color={occurrence.data.dangerousness == 1 ? '#795fd8' : '#adadad'}
                  size={40}
                />
                <label 
                  style={{
                    fontSize: 20, 
                    color: occurrence.data.dangerousness == 1 ? '#795fd8' : '#adadad'
                  }}
                >
                  Média
                </label>
              </div>
            </button>
            <button 
              disabled={false} 
              onClick={() => {}}
            >
              <div style={{alignItems: 'center'}}>
                <FaThermometerFull
                  className={occurrence.data.dangerousness  == 2? 'data-cy-qrcode-print-level-high-active' : 'data-cy-qrcode-print-level-high-disable'}
                  color={occurrence.data.dangerousness == 2 ? '#ff4800' : '#adadad'}
                  size={40}
                />
                <label style={{
                  fontSize: 20, 
                  color: occurrence.data.dangerousness == 2 ? '#ff4800' : '#adadad'}}
                >
                  Alta
                </label>
              </div>
            </button>
          </div>
        </Content>
      </Form>
    </Container>
  );
}

export default StepOne;
