import React, {useEffect} from 'react';
import { Form, Input } from '@rocketseat/unform';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from './styles';

import { setOccurrence } from '~/store/modules/occurrence/actions'
import { setValidation } from '~/store/modules/validButton/action';

function StepThree() {
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.occurrence);
  const validButton = useSelector(state => state.validButtonState) 

  useEffect(() => {    
    dispatch(setValidation({
      ...validButton.data, 
      step3: {
        ...validButton.data.step3,
        valid:(data.code && data.code.length > 3)
      }
    }))        
  }, [data.code])

  return (  
    <Container>
      <Form onSubmit={() => {}}>
        <div>
          <label className="data-cy-qrcode-type-token-label">Por favor digite abaixo o token enviado por SMS para seu celular:</label>
          <Input
            className="data-cy-qrcode-token-input"
            type="text"
            name="code"
            inputmode="numeric"
            placeholder="código"
            autoComplete="off"
            value={data.code}
            maxLength={4}
            onChange={e => dispatch(setOccurrence({ ...data, code: e.target.value.replace(/[^\d]+/g, '') }))}
          />
        </div>
      </Form>
    </Container>
  );
}

export default StepThree;
