import styled from 'styled-components';

//import { lighten } from 'polished'

export const Container = styled.div`
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 10;
  width: 100%;
  top: 0;
  left: 0;
  padding: 20px;
`;

export const Circle = styled.div`
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: ${({ type }) => 
    type === 'error' 
      ? '6px #ff4e4e solid' 
        : '6px #0bd00b solid'};
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  padding-top: 4px;
  margin-bottom: 10px;
  margin-top: 15%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  background: #ffffffe8;
  width: 60%;
  border-radius: 4px;

  @media only screen and (max-width: 800px) {
    width: 100%;
  }

  > div.buttons-bottom-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0px 40px;
    margin-top: 15%;

    > button {
      border: none;
      border-radius: 50px;
      padding: 16px 0px;
      font-size: 16px;
      background: linear-gradient(90deg, rgba(104,153,255,1) 35%, rgba(160,96,187,1) 100%); 
      color: #fff;
      opacity: 1;
      width: 100%;

      &:disabled {
        opacity: 0.6;
      }

      &:active {
        transition: 0.2s;
        opacity: 0.6;
      }
    }

    > button + button {
      margin-top: 10px;
    }
  }
`;

export const Title = styled.h1`
  color: #232323;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 50%;
  /* text-shadow: 0px 0px 3px #0000003b; */
  margin-top: 15%;
`;
