import produce from 'immer';

const INITIAL_STATE = {
  data: {
    loading: true,
    motivos: [],
    background: '#599bcb',
    incidentQrCodeConfiguration: null,
    logo: '',
    follow: true,
    photoEnforced: false,
    valid: false,
    signed: false,
    localId: 0,
    url: '',
    resolutionTime: '',
    unitId: null,
    incidentId: null,
    code: null,
    anonymous: false, 
  }
};

export default function configuration(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@configuration/SET_CONFIGURATION': {
        draft.data = action.payload.data;       
        break;
      }
      case '@configuration/SET_CONFIGURATION_LOADING': {
        draft.data.loading = action.payload.data;
        break;
      }
      case '@configuration/RESET_CONFIGURATION': {
        draft.data = INITIAL_STATE
        break;
      }
      default:
    }
  });
}
