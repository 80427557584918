/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify'

import { useDispatch, useSelector } from 'react-redux'

import { Container, Content, Title } from './styles';

import FeedBack from '~/Components/Feedback';

import LGPD from '~/Components/LGPD';

import Loading from '~/Components/Loading'

import api from '~/services/api'

import { setOccurrence, resetOccurrence } from '~/store/modules/occurrence/actions';

import { setConfig } from '~/store/modules/configuration/actions';

import { changeFeedBack } from '~/store/modules/feedBack/actions'

import { setLGPD } from '~/store/modules/LGPD/actions'

import StepOne from './components/Steps/StepOne'
import StepTwo from './components/Steps/StepTwo'
import StepThree from './components/Steps/StepThree'
import StepFourth from './components/Steps/StepFourth'
import StepFive from './components/Steps/StepFive'

import StepIndicator from '~/Components/StepIndicator'

import firebaseDatabase from '~/services/firebase'
import { ref, set } from "firebase/database"

function Occurrence({ history }) {
  const dispatch = useDispatch()
  const { data } = useSelector(state => state.occurrence);
  const { show, type } = useSelector(state => state.feedBack);
  const { url } = useSelector(state => state.configuration.data);
  const lgpd = useSelector(state => state.lgpd.data);
  const configuration = useSelector(state => state.configuration.data);
  const validButton = useSelector(state => state.validButtonState.data) 

  const [step, setStep] = useState(1);
  const [disabled, setDisabled] = useState(false);
  const [loadButton, setLoadButton] = useState(false);
  const [component, setComponentns] = useState([
    {
      component: <></>
    },
    {
      component: <StepOne />, 
      key: 'step1',
    },
    {
      component: <StepTwo />,
      key: 'step2',
    },
    {
      component: <StepThree />,
      key: 'step3',
    },
    {
      component: <StepFourth />,
      key: 'step4',
    },
    {
      component: <StepFive />,
      key: 'step5',
    },
  ]);

  useEffect(() => {
    setLoadButton(false);
    setDisabled(false);
    dispatch(setLGPD({ ...lgpd, accepted: false, show: false }));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStep(1)
  }, []);  

  useEffect(() => {     
    let arrayComponents = [
      {
        component: <></>
      },
      {
        component: <StepOne />,
        key: 'step1'
      },
      {
        component: <StepTwo />,
        key: 'step2'
      },
      {
        component: <StepThree />,
        key: 'step3'
      }
    ];

    if(data.automatic && data.fixedDescription && data.title && data.description){
      if(!data.photoEnforced && data.photoEnforcedType === 'NEVER'){
        arrayComponents.push({
          component: <StepFourth />,
          key: 'step4'
        });
        
      }else{
        arrayComponents.push({
          component: <StepFive />,
          key: 'step5'
        });
        
      }
    }else if(data.anonymous && data.photoEnforcedType !== 'NEVER'){
      let newArrayComponents2 = [
        {
          component: <></>
        },
        {          
          component: <StepOne />,
          key: 'step1'
        },
        {
          component: <StepFourth />,
          key: 'step4'
        },
        {
          component: <StepFive />,
          key: 'step5'
        }
      ];
      
      
      arrayComponents = newArrayComponents2
      

    }else if(data.anonymous){
      let newArrayComponents = [
        {
          component: <></>
        },
        {          
          component: <StepOne />,
          key: 'step1'
        },
        {
          component: <StepFourth />,
          key: 'step4'
        }
      ];
      
      arrayComponents = newArrayComponents       
      
    }else if(!data.photoEnforced && data.photoEnforcedType === 'NEVER'){
      arrayComponents.push({
        component: <StepFourth />,
        key: 'step4'
      });
      
    }else{
      arrayComponents.push(
        {
          component: <StepFourth />,
          key: 'step4'
        },
        {
          component: <StepFive />,
          key: 'step5'
        }
      )
    }

    if(data.anonymous && data.fixedDescription){
      let newArrayComponents = [
        {
          component: <></>
        },
        {          
          component: <StepOne />,
          key: 'step1'
        }
      ];
      
      arrayComponents = newArrayComponents  

    }
    
    let actualStep = component[step].key
    
    let arrayValidButton = Object.keys(validButton).map((key) => {  
      return {
        valid: validButton[key].valid,
        key: validButton[key].key
      };
    });

    setDisabled(!arrayValidButton.filter(
      res => res.key === actualStep
    )[0].valid)

    setComponentns(arrayComponents);
  }, [data, validButton]);

  async function sendPostCreateOccurence(){   
    try{
      const { data: response } = await api.post('IncidentType/PostCreateIncidentByQrCode', {
        title: data.title,
        incidentTypeId: parseInt(data.motivo),
        description: data.description,
        presential: false,
        dangerousness: data.dangerousness,
        unitId: data.unitId,
        localId: data.localId,
        IncidentQrCodeUserId: data.anonymous
          ? 1
          : data.qr_code_user_id,
        IncidentFiles: data.image.length > 0
          ? data.image
          : null,
      });

      const newOcc = {
        incident_id: response.incidentId,
        status: 'Open',
        date: '' + new Date().toString(),
      };
  
      set(
        ref(
          firebaseDatabase, 
          '/incident-creation-or-change/' + data.unitId + '/' + response.incidentId 
        ), 
        newOcc
      );
  
      dispatch(setOccurrence({ 
        ...data,
        protocol: response.link,          
      }));

      dispatch(changeFeedBack({
        show: true,
        type: 'success',
        message: '',
        typeCreated: data.type
      }));
    }catch(err){
      dispatch(changeFeedBack({
        show: true,
        type: 'error',
        message: '',
        typeCreated: data.type
      }));
    }
  }

  async function next() {
    setDisabled(true);

    /*setTimeout(() => {
      setDisabled(false);
    }, 2000);*/
    let typeFeedBack = 'success'

    if(component.filter(res => res.key === 'step1').length > 0 && component[step].key === 'step1' && data.anonymous && data.fixedDescription){
      sendPostCreateOccurence()      
      return
    }

    if(component.filter(res => res.key === 'step2').length > 0 && component[step].key === 'step2'){
      dispatch(setLGPD({ ...lgpd, show: true }))


      return      
    }  
    
    if(component.filter(res => res.key === 'step3').length > 0 && component[step].key === 'step3'){
      
      try {
        const { data:response } = await api.post('IncidentType/PostValidIncidentQrCodeUser?Type=1', {
          ActualToken: data.code,
        });

        const { is_valid, message, qr_code_user_id } = response        

        if(is_valid) {
          toast.success('Código validado com sucesso!');

          dispatch(setOccurrence({ 
            ...data,
            qr_code_user_id,
          }));
        } else {      
          toast.error(message);

          return
        }

        setLoadButton(false);
        setDisabled(false);
      } catch(error) {
        toast.error(error.response.data.Message);
      }        
    }   
    
    if(component.filter(res => res.key === 'step4').length > 0 && component[step].key === 'step4'){  
      if(data.automatic && data.fixedDescription && data.title && data.description){
        if(!data.photoEnforced && data.photoEnforcedType === 'NEVER'){
          sendPostCreateOccurence()        
          return
        }   
      } else if(!data.anonymous && !data.photoEnforced && data.photoEnforcedType === 'NEVER') {
        sendPostCreateOccurence()
        return    
      } else if(data.anonymous && data.photoEnforcedType === 'NEVER') {
        sendPostCreateOccurence()
        return      
      } 
    }

    if(component.filter(res => res.key === 'step5').length > 0 && component[step].key === 'step5'){        
      sendPostCreateOccurence()
      
      return                
    }
    
    if (step < component.length-1) {
      setStep(step+1);
    }     
  }

  async function createCode() {    
    setDisabled(true)
    setLoadButton(true)
    
    await api.post('IncidentType/PostInsertIncidentQrCodeUser?localid='+configuration.localId, {
      ActualName: data.name,
      PhoneNumber: data.phone
    });        
    
    dispatch(setLGPD({ show: false, accepted: true }))

    setLoadButton(false)
    setDisabled(false)  

    setStep(step+1)
  };
    
  return (
    <Container height={'auto'}>
      {lgpd.show && <LGPD callback={createCode} />}
      {show && <FeedBack type={type} setStep={setStep} history={history}/>}
       <Content>
          <Title className="data-cy-qrcode-new-action-title">{`${data.type == 'INCIDENTE' ? 'Crie um novo incidente:': data.type == 'SOLICITAÇÃO' ? 'Crie uma nova solicitação:' : 'Crie um novo acionamento:'} `}</Title>
          <StepIndicator 
            step={step} 
            qtd={component.length-1} 
          />
          {console.log('step', component, step)}
          
          {step && (
            component[step].component
          )}
          <div className="buttons-bottom-container">
            <button 
              className={step === 3 || step === 4?"data-cy-qrcode-step-one-button-back-disable" : "data-cy-qrcode-step-one-button-back-active"}
              onClick={() => {
                if(step === 1) {
                  history.push(`/?url=${url}`)
                } else {
                  setStep(step-1)
                }
              }}
              disabled={step === 3 || step === 4}
            >Voltar</button>
            <button className={disabled? "data-cy-qrcode-step-one-button-continue-disable" : "data-cy-qrcode-step-one-button-continue-active"}  onClick={next} disabled={disabled}>
              {console.log('step === 1 && data.anonymous && data.fixedDescription', step, data.anonymous, data.fixedDescription, data.automatic)}
              {
                loadButton
                ? 
                  <Loading show={true} type={'small'} />
                : 
                  step === 1 && data.anonymous && data.fixedDescription
                  ? 
                    'Finalizar' 
                  :
                  (
                    step === 5 
                    || 
                    (step === 3 && component.length-1 === 3) 
                    || 
                    (step === 4 && !data.photoEnforced && data.photoEnforcedType && data.photoEnforcedType.toUpperCase() === 'NEVER')  
                    ||
                    (step === 3 && data.anonymous && data.photoEnforcedType !== 'NEVER') 
                    || 
                    (step === 2 && data.anonymous && component.length-1 === 2) 
                    ? 
                      'Finalizar'
                    : 
                      step === 3 && !data.qr_code_user_id ? 'Validar' :'Continuar'
                  )
                }
            </button>
          </div>          
        </Content>
    </Container>
  );
}

export default Occurrence;
