import produce from 'immer';

const INITIAL_STATE = {
  height_default_of_layout_default: true,
};

export default function configurationSystem(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@configurationSystem/CHANGE_OF_LAYOUT_DEFAULT': {
        draft.height_default_of_layout_default = action.payload;        
        break;
      }
      default:
        return state;
    }
  });
}
