import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #000000e3;
  z-index: 9999;

  > .viewing-full-images {
    width: auto !important;
    display: flex;
    height: 100% !important;
    justify-content: center;
    align-items: center;
  }

  > .owl-carousel.owl-drag .owl-item {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .owlC,
  .owlC div {
    height: 100% !important;
  }

  > .owl-carousel .owl-item img {
    display: block;
    width: auto !important;
    height: 100%;

    @media only screen and (max-width: 800px) {
      height: auto !important;
      width: 100% !important;
    }
  }
`;

export const Closed = styled.button`
  width: 60px;
  height: 60px;
  background: transparent;
  border: none;
  position: fixed;
  right: 15px;
  top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;
