import styled from 'styled-components';

export const Container = styled.div`  
  height: 100%;

  > form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 80%;

    > div {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      > label {
        font-weight: 600;
        color: #333;
      }

      > input {
        margin-top: 10px;
        padding: 15px;
        border: none;
        border-radius: 50px;
        background: transparent;
        font-size: 16px;
        width: 100%;
        color: #333;
        font-weight: 500;
        background: #fff;

        > &::placeholder {
          color: #777 !important;
        }
        > &:invalid {
          color: gray;
        }
      } 

      textarea {
        margin-top: 10px;
        padding: 15px;
        border: none;
        background: transparent;
        font-size: 16px;
        width: 100%;
        background: #fff;
        color: #333;
        font-weight: 500;
        resize: none;
        border-radius: 20px;
        font-family: Arial, Helvetica, sans-serif;

        > &::placeholder {
          color: #777 !important;
          font-family: Arial, Helvetica, sans-serif;
        }

        > &:invalid {
          color: gray;
        }
      }

      textarea::placeholder {
        font-family: Arial, Helvetica, sans-serif;
      }
    }
  }
`;