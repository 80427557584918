/* eslint-disable import/no-extraneous-dependencies */
import { createGlobalStyle } from 'styled-components';

import 'react-perfect-scrollbar/dist/css/styles.css';

import 'react-toastify/dist/ReactToastify.css';

import 'react-perfect-scrollbar/dist/css/styles.css';

import 'owl.carousel/dist/assets/owl.carousel.css';

import 'owl.carousel/dist/assets/owl.theme.default.css';

import 'react-confirm-alert/src/react-confirm-alert.css';

export default createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    outline: none !important;
    box-sizing: border-box !important;
    outline-color: transparent !important;
    outline: none !important;
    -webkit-tap-highlight-color: rgba(0,0,0,0) !important;
  }
  
  html, body, #root {
    height: 100%;
    overflow-x: hidden;
  }
  body {
    -webkit-font-smoothing: antialiased;
  }
  body, input, button {
    font: 14px 'Roboto', sans-serif;
  }
  ul, li {
    /* list-style:none; */
  }
  ol {
    margin-left: 16px;
  }
  button {
    cursor:pointer;
  }
  a {
    text-decoration: none;
  }
  input, textarea, select {
    border: 2px #ddd solid !important;
    /* border: 2px #ff7716 solid !important; */
    /* background: #ffe7b8 !important; */
    /* color: #000000cf !important; */
  }

  @media only screen and (max-width: 800px) {
    .react-confirm-alert-body {
      width: 98% !important;
    }  
  }
`;
