import React, { useEffect, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';

import { toast } from 'react-toastify'

import { useDispatch, useSelector } from 'react-redux'

import { Container, Column, Content, ContentErrorQrCode, ContentLogo, Salutation } from './styles';

import Loading from '../../Components/Loading'

import api from '../../services/api'

import { setConfig, setLoading, resetConfig } from '../../store/modules/configuration/actions'

import { resetOccurrence } from '../../store/modules/occurrence/actions'

import { change_height_default_of_layout_default } from '~/store/modules/configurationSystem/actions'

import { erroQrcode } from '../../assets'

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Home({ history }) {
  const dispatch = useDispatch();
  const configuration = useSelector(state => state.configuration.data);
  const loading = useSelector(state => state.configuration.data.loading);
  const [showErroQrcode, setShowErroQrcode] = useState(false)
  const [saudacao, setSaudacao] = useState('')
  let query = useQuery();

  useEffect(() => {
    window.sessionStorage.removeItem('validCell')

    async function init(url) {
      dispatch(setLoading(true));
      dispatch(resetOccurrence());

      try {
        let { data: response } = await api
          .get(`IncidentType/GetListIncidentTypeIncidentQrCodeConfigLocal/${url}`);
        
        const taskButton = response.taskButton;
        
        const reservationButton = response.reservationButton;

        response = response.configuration[0];

        const { incidentType, incidentQrCodeConfiguration } = response

        const motivos = incidentType.length > 0 ?  
          incidentType.map(res => { 
            return { 
              ...res,
              title: res.name, 
            }
          }): [];

        dispatch(setConfig({ 
          ...configuration,
          motivos,
          background: incidentQrCodeConfiguration.colorCode,
          logo: incidentQrCodeConfiguration.imageKey,
          follow: incidentQrCodeConfiguration.follow,
          description: incidentQrCodeConfiguration.text,
          localId: incidentQrCodeConfiguration.localId,
          unitLocalName: incidentQrCodeConfiguration.unitLocalName,
          incidentQrCodeConfigurationid: incidentQrCodeConfiguration.id,
          signed: true,
          url,
          taskButton,
          reservationButton,
        }))
        dispatch(change_height_default_of_layout_default(true))
        setTimeout(() => {
          dispatch(setLoading(false));
        }, 1000)
      } catch(error) {
        toast.error(
          error &&
          error.response &&
          error.response.data &&
          error.response.data.Message? error.response.data.Message : 'Ocorreu um erro!')
        setTimeout(() => {
          setShowErroQrcode(true)
        }, 10000)
      }
    }

    if(query.get("url")){
      dispatch(resetConfig())
      init(query.get("url"))
    } else {
      dispatch(resetConfig())
      dispatch(setLoading(true))
      setTimeout(() => {
        setShowErroQrcode(true)
      }, 10000)
    }
    let data = new Date();
    let hora = data.getHours();
    if (hora >= 0 && hora <= 12) {
      setSaudacao( "Bom dia, ");
    } else if (hora > 12 && hora <= 18) {
      setSaudacao("Boa tarde, ");
    } else if (hora > 18 && hora <= 24) {
      setSaudacao("Boa noite, ");
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(configuration.description) {
      setTimeout(() => {
        document.getElementById('description').innerHTML = '';
        document.getElementById('description').innerHTML = configuration.description || '';
      }, [200]);
    }
  }, [configuration.description])

  if(showErroQrcode) {
    return (
    <Container>
      <Column  />
      <Column >
        <ContentErrorQrCode>
          <div>
            <img src={erroQrcode} className="data-cy-qrcode-home-error-image" alt="error QrCode" />
          </div>
          <div>
            <h2><strong className="data-cy-qrcode-home-cannot-read-qrcode">Não foi possivel ler o QRcode</strong></h2>
            <p className="data-cy-qrcode-home-contact-responsible">
            Entre em contato com o responsável pelo local para maiores informações.
            </p>
          </div>
        </ContentErrorQrCode>
      </Column> 
      <Column  />
    </Container>
    )
  }

  return (
    <Container>
      <Column  />
      <Column >
        <Content opacity={loading}>
          <div className='loading'>
            <Loading show={loading} />
          </div>
          <div className='content'>
            <ContentLogo>
              <img src={configuration.logo} className="data-cy-qrcode-home-image" alt="Carregando..." />
            </ContentLogo>
            <Salutation className="data-cy-qrcode-home-salution"><span>{saudacao}</span><span>seja bem vindo(a)!</span></Salutation>
            <div id="description" className="data-cy-qrcode-home-description" style={{marginTop: '10px', display: 'contents' }}></div>
            <div id="unitLocalName" className="data-cy-qrcode-home-local-name">{configuration.unitLocalName}</div>
            <div>
              <Link className="data-cy-qrcode-home-new-action" to="/occurrence">Novo acionamento</Link>
              {configuration.reservationButton && (
                <button
                  onClick={() => null}
                >Nova reserva</button>
              )}
              {configuration.taskButton && (
                <button
                  onClick={() => {
                    history.push(`/tasksperformed?code=${btoa(JSON.stringify({
                      localId: configuration.localId,
                      random: new Date()
                    }))}`)
                  }}
                >Histórico de atividades</button>
              )}
            </div>
          </div>
        </Content>
      </Column> 
      <Column  />
    </Container>
  );
}

export default Home;
