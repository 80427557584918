import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100%;
  display: flex;
  width: 100%;
  padding: 20px;
  box-sizing: border-box !important;
  justify-content: center;
  margin-bottom: 20px;
`;

export const Content = styled.div`
  display: flex;
  /* height: 100%; */
  background: #ffffffc9;
  border-radius: 4px;
  box-shadow: 0px 0px 4px 0px #00000024;
  flex-direction: column;
  padding: 10px 20px;
  justify-content: space-between;
  width: 60%;

  > .content {
    > * + * {
      margin-top: 8px;
    }

    > .content_images {
      display: grid;
      grid-template-columns: 
        33.3333333333%
        33.3333333333%
        33.3333333333%;
    }
  }

  @media only screen and (max-width: 800px) {
    width: 100%;
  }

  
  > div.buttons-bottom-container {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    justify-content: space-between;
    padding: 0px 0px;

    > button {
      border: none;
      border-radius: 50px;
      padding: 16px 0px;
      font-size: 16px;
      background: linear-gradient(90deg, rgba(104,153,255,1) 35%, rgba(160,96,187,1) 100%); 
      color: #fff;
      opacity: 1;
      width: 100%;

      &:disabled {
        opacity: 0.6;
      }

      &:active {
        transition: 0.2s;
        opacity: 0.6;
      }
    }
  }
`;


export const Title = styled.h2`
  color: #333;
`

export const Thumbnail = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 15px;
  margin-bottom: 15;
  border-Bottom-color: "#ccc";
  border-bottom-width: 1px;
  padding: 5px;
  cursor: pointer;
  justify-content: space-between;

  /* &:hover {
    margin-top: -3px;
    transition: 0.2s;
  } */
  
  > div:nth-child(1) {
    height: 64px;
  }

  > div:nth-child(2) {
    width: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      width: 100%;
      /* max-width: 100%; */
      background: #fff;
      padding: 5px;
      border-radius: 10px;

      &:hover {
        box-shadow: 0px 4px 10px 0px #44444421;
        transition: 0.2s;
      }
    }
  }
`

export const TitleStatus = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 0px;
  color: #00f;
  font-size: 10px;
  text-align: center;
`;

export const ResponseName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 0px;
  padding-bottom: 10px;
  font-size: 12px;
  color: #6c6c6c;
  width: 100%;
  text-align: center;
` 