import storage from 'redux-persist/lib/storage';

import { persistReducer } from 'redux-persist';
// eslint-disable-next-line import/no-anonymous-default-export
export default reducers => {
  const persistedReducer = persistReducer(
    {
      key: 'wip-ext_qr_code',
      storage,
      whitelist: ['occurrence', 'detailsOccurrence', 'lgpd', 'configurationSystem', 'endpointErrorSave'],
    },
    reducers
  );

  return persistedReducer;
};
