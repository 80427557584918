/* eslint-disable no-unreachable */
import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify'

import { useDispatch, useSelector } from 'react-redux';

import { parseISO, format } from 'date-fns';

import {
  FaCircle,
  FaList,
  FaComment,
  FaClock,
  FaExclamationTriangle,
  FaMapMarkerAlt,
} from 'react-icons/fa';

import {
  Container,
  Content,
  CardRow,
  CardRowButton,
  OpenStatus,
  OngoingStatus,
  CancelledStatus,
  FinishedStatus,
  PendentStatus,
  RefusedStatus,
  OtherStatus,
  ForwardedStatus,
  ReopenedStatus,
  H4,
  LoadTime,
  ContentErrorQrCode,
  ContentExpiredCode,
  ColumnExpiredCode,
  ContainerExpiredCode
} from './styles';

import { setDetailsOccurrence } from '~/store/modules/detailsOccurrence/actions';
import { change_height_default_of_layout_default } from '~/store/modules/configurationSystem/actions';

import Loading from '~/Components/Loading';

import Stopwatch from './Components/Stopwatch';

import api from '~/services/api';

import { setConfig } from '~/store/modules/configuration/actions';

import StepOne from './Components/Steps/One';

import StepTwo from './Components/Steps/Two';

import { erroTokenQrcode, expiredAccessImage } from '~/assets/';

import firebaseDatabase from '~/services/firebase'
import { ref, onValue } from "firebase/database"
import { changeFeedBack } from '../../../store/modules/feedBack/actions'

function cardRow(icon, title, description, type, url) {
  if (type === 'link') {
    return (
      <CardRowButton to={`/${url}`}>
        <div>{icon}</div>
        <div>
          <H4 className={`data-cy-qrcode-${title.toLowerCase().replace(':', '')}-label`}>{title}</H4>
          <H4 className={`data-cy-qrcode-${title.toLowerCase().replace(':', '')}-value`}>{description}</H4>
        </div>
      </CardRowButton>
    );
  }

  return (
    <CardRow>
      <div>
        {icon}
      </div>
      <div>
        <H4 className={`data-cy-qrcode-${title.toLowerCase().replace(':', '')}-label`}>{title}</H4>
        <H4 className={`data-cy-qrcode-${title.toLowerCase().replace(':', '')}-value`}>{description}</H4>
      </div>
    </CardRow>
  );
}

function getStatus(data) {
  if (data.toUpperCase() === 'OPEN') {
    return <OpenStatus>ABERTO</OpenStatus>;
  } if (data.toUpperCase() === 'ONGOING') {
    return <OngoingStatus>EM ANDAMENTO</OngoingStatus>;
  } if (data.toUpperCase() === 'CANCELLED') {
    return <CancelledStatus>CANCELADO</CancelledStatus>;
  } if (data.toUpperCase() === 'FINISHED') {
    return <FinishedStatus>FINALIZADO</FinishedStatus>;
  } if (data.toUpperCase() === 'PENDENT') {
    return <PendentStatus>PENDENTE</PendentStatus>;
  } if (data.toUpperCase() === 'REFUSED') {
    return <RefusedStatus>RECUSADO</RefusedStatus>;
  } if (data.toUpperCase() === 'CLOSED') {
    return <RefusedStatus>FECHADO</RefusedStatus>;
  } if (data.toUpperCase() === 'REOPENED') {
    return <ReopenedStatus>REABERTO</ReopenedStatus>;
  } if (data.toUpperCase() === 'FORWARDED') {
    return <ForwardedStatus>ENCAMINHADO</ForwardedStatus>;
  }
  return <OtherStatus>{data}</OtherStatus>;
}

function getDangerousness(item) {
  switch (item) {
    case 'Medium':
      return 'Média';
      break;
    case 'High':
      return 'Alta';
      break;
    case 'Low':
      return 'Baixa';
      break;
    default:
  }
}

function Details({ history }) {
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.detailsOccurrence);
  const { url } = useSelector(state => state.configuration.data);
  const occurrence = useSelector(state => state.occurrence);
  const configuration = useSelector(state => state.configuration.data);
  const [showTime, setShowTime] = useState(false);
  const [validCell, setValidCell] = useState(false);
  const [token, setToken] = useState('');
  const [codeValue, setCodeValue] = useState('');
  const [cancellActivate, setCancellActivate] = useState(false);
  const [showErrorNotAccessAnonymus, setShowErrorNotAccessAnonymus] = useState(false);
  const [components,] = useState([
    {
      component: <></>
    },
    {
      component: <StepOne />
    },
    {
      component: <StepTwo setCodeValue={setCodeValue}  />
    },
  ]);
  
  const [componentsIndex, setComponentsIndex] = useState(1);
  const [loading, setLoading] = useState(true);
  const [expiredAccess, setExpiredAccess] = useState(false);
  const[customTitle, setCustomTitle] = useState('Título')
  const[customDescription, setCustomDescription] = useState('Descrição')
  
console.log('url1', url)
  useEffect(() => {   
    if(occurrence.data){
      let currentMotivo = configuration.motivos.filter(
        (value) => {
          return value.id === parseInt(occurrence.data.motivo)
        }
      ) [0]

      if(
        currentMotivo
        && currentMotivo.customFieldsEnable 
        && Array.isArray(currentMotivo.customFieldsNames) 
        && currentMotivo.customFieldsNames.length === 2
      ){
        console.log('currentMotivo.customFieldsNames--->', currentMotivo.customFieldsNames)
        if(currentMotivo.customFieldsNames[0]){
          setCustomTitle(currentMotivo.customFieldsNames[0])
        }
        if(currentMotivo.customFieldsNames[1]){
          setCustomDescription(currentMotivo.customFieldsNames[1])
        }
      }
    }

  }, occurrence.data)

  useEffect(() => {
    if(!!window.sessionStorage.getItem('validCell')) {
      setValidCell(true);

      const protocol = history.location.pathname.split('/')[2].substr(0, 16);

      const code = history.location.pathname.split('/')[2].substr(20);

      const simulateAvailable = {
        available: true,
        message: ''
      }

      initDetails(protocol, code, simulateAvailable)
    }

    if(configuration.anonymous) {
      setValidCell(true);

      const protocol = history.location.pathname.split('/')[2].substr(0, 16);

      const simulateAvailable = {
        available: true,
        message: ''
      }

      initDetails(protocol, 1, simulateAvailable)
    }

    async function validViewDetails(protocol) {  
      setLoading(true);  

      try {
        const { data } = await api.get(`incident/getTrackingTheDetails/${protocol}`);

        if(data.message === "Como esse acionamento foi aberto usando recurso de anonimato o acompanhamento do mesmo não é permitido") {
          setShowErrorNotAccessAnonymus(data.message)
          dispatch(change_height_default_of_layout_default(false));
          setTimeout(() => {
            toast.error(data.message);
            
            setLoading(false); 
          }, 2000)
          return
        }

        if(!data.available && data.message === 'Os dados desse acionamento não estão mais disponíveis para consulta.') {
          setExpiredAccess(true);
          dispatch(change_height_default_of_layout_default(false));
          
          toast.error(data.message);

          setTimeout(() => {
            setLoading(false); 
          }, 2000)          
          return
        }

        if(data.available) {
          // initDetails(protocol, null, data);
          dispatch(setConfig({
            ...configuration,
            background: data.qrCodeConfiguration.colorCode,
            logo: data.qrCodeConfiguration.image.data,
            follow: true,
            description: '',
            signed: true,
            localId: data.qrCodeConfiguration.id,
            resolutionTime: 0,
            code: data.phoneNumber
          }));

          setLoading(false); 

          dispatch(change_height_default_of_layout_default(true))
        } else {
          setCancellActivate(true);
          dispatch(change_height_default_of_layout_default(true))
        }
      } catch(error) {
        setLoading(false);
        toast.error(error.response.data.Message);
        if(error.response.data.Message === 'Os dados desse acionamento não estão mais disponíveis para consulta.') {
          setExpiredAccess(true);
          dispatch(change_height_default_of_layout_default(false));
          
          toast.error(data.message);

          setTimeout(() => {
            setLoading(false); 
          }, 2000)
          return
        }
      }
    }

    if (history.location.pathname.split('/')[2] && 
      history.location.pathname.split('/')[2].length > 11) {

      const protocol = history.location.pathname.split('/')[2].substr(0, 16);

      validViewDetails(protocol);     
    } else {
      history.push(`/?url=${url}`);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(data.status) {
      setShowTime(true);
    }
  }, [])

  async function initDetails(protocol, code, available) {
    setShowTime(false);
    
    try {
      const { data: response } = await api.get(
        `IncidentType/GetIncidentDetailsByQrCode/${protocol}/${code}`,
      );

      let { incident } = response;

      if(!incident) {
        toast.error(response)
        return
      }

      incident = incident[0];
      
      const { incidentExternalAccessAx, attendanceTime, images } = response;

      const { incidentQrCodeUser } = incidentExternalAccessAx;

      console.log('nombre1', data.name)
 
      dispatch(setConfig({
        ...configuration,
        background: incident.qrCodeConfiguration.colorCode,
        logo: incident.qrCodeConfiguration.imageKey,
        follow: true,
        description: incident.qrCodeConfiguration.text,
        signed: true,
        localId: incident.localId,
        resolutionTime: incident.resolutionTime,
      }));

      if(!available.available) {
        toast.error(available.message);
        return
      }

      console.log('incidentExternalAccessAx--->', incidentExternalAccessAx)
      
      dispatch(setDetailsOccurrence({
        ...data,
        protocol: incident.protocol,
        protocolAcessForDetails: history.location.pathname.split('/')[2],
        description: incident.description,
        name: incidentExternalAccessAx.incidentQrCodeUser.actualName,
        motivo: incident.incidentTypeName,
        status: incident.status,
        dangerousness: incident.dangerousness,
        attendanceTime,
        title: incident.title,
        localName: incident.localName,
        data: incident.date,
        history: incident.history,
        user_id: incidentQrCodeUser.id,
        type: incident.type,
        images: images.map((res) => ({
          typeStatus: res.typeStatus,
          thumbnail: res.data,
          image: res.data,
          createByName: res.createByName,
        })),
      }));

      startListener(protocol, code, incidentExternalAccessAx.unitId, incident.id);

      setShowTime(true);

      setLoading(false); 
    } catch (error) {
      setLoading(false);

      toast.error(error.response.data.Message)
    }
  }
  

  async function next() {
    if(componentsIndex === 1) {
      const protocol = history.location.pathname.split('/')[2].substr(0, 16);
      const code = history.location.pathname.split('/')[2].substr(20);

      try {
        await api.post('incidentType/GenerateTokenDetailsIncidentQrCode/'+code+'/'+protocol);

        setComponentsIndex(2);
        setToken(true)
      } catch (error) {
        setToken(false)
        toast.error(error && error.response && error.response.data && error.response.data.Message);
      }
    } else if (componentsIndex === 2) {
      console.log('aquiii', codeValue, token)
      if(codeValue && token) {
        try {
          const { data:response } = await api.post('IncidentType/PostValidIncidentQrCodeUser?Type=2', {
            ActualToken: codeValue,
          });
  
          const { is_valid, message, qr_code_user_id } = response        
  
          if(is_valid) {
            setValidCell(true);
            const protocol = history.location.pathname.split('/')[2].substr(0, 16);
            const code = history.location.pathname.split('/')[2].substr(20);
            const simulateAvailable = {
              available: true,
              message: ''
            }
            // startListener(protocol, code);
            // protocolo, codigo, avaliable
            initDetails(protocol, code, simulateAvailable);
            window.sessionStorage.setItem('validCell', 'true');
          }else{
            toast.error('Token inválido');
          }  
          
        } catch(error) {
          toast.error(error.response.data.Message);
        }        
        
      } else {
        toast.error('Token inválido');
      }
    }
  } 

  async function updateStatus(protocol, code) {
    try {
      const { data: response } = await api.get(
        `IncidentType/GetIncidentDetailsByQrCode/${protocol}/${code}`,
      );

      let { incident } = response;

      incident = incident[0];
      
      const { incidentExternalAccessAx, attendanceTime, images } = response;
      const { incidentQrCodeUser } = incidentExternalAccessAx;

      
      dispatch(setDetailsOccurrence({
        ...data,
        protocol: incident.protocol,
        protocolAcessForDetails: history.location.pathname.split('/')[2],
        description: incident.description,
        name: incidentExternalAccessAx.incidentQrCodeUser.actualName,
        motivo: incident.incidentTypeName,
        status: incident.status,
        dangerousness: incident.dangerousness,
        attendanceTime,
        title: incident.title,
        localName: incident.localName,
        data: incident.date,
        history: incident.history,
        user_id: incidentQrCodeUser.id,
        type: incident.type,
        images: images.map((res) => ({
          typeStatus: res.typeStatus,
          thumbnail: res.data,
          image: res.data,
          createByName: res.createByName,
        })),
        incidentId: incidentExternalAccessAx.id,
        unitId: incidentExternalAccessAx.unitId,
      }));      
    } catch (error) {
      setLoading(false)
      toast.error(error.response.data.Message)
    }
  }

  console.log('nombre2', data.name)

  async function startListener(protocol, code, unitId, incidentId) {
    onValue(ref(firebaseDatabase, `/incident-creation-or-change/${unitId}/${incidentId}`), (snapshot) => {
      updateStatus(protocol, code);
    });
  }

  if(showErrorNotAccessAnonymus) {
    return (
      <ContainerExpiredCode>
        <ColumnExpiredCode />
        <ColumnExpiredCode >
          <ContentExpiredCode style={{
            justifyContent: loading? 'center': 'initial'
          }}>
            <div>
              <img src={erroTokenQrcode} alt="error QrCode" />
            </div>
            <div>
              <h2><strong>Alerta!</strong></h2>
              <p style={{ fontSize: '16px' }}>
              {showErrorNotAccessAnonymus}
              </p>
            </div>
          </ContentExpiredCode>
        </ColumnExpiredCode> 
        <ColumnExpiredCode />
      </ContainerExpiredCode>
    ) 
  }

  if(expiredAccess) {
    return (
      <ContainerExpiredCode>
        <ColumnExpiredCode  />
        <ColumnExpiredCode >
          <ContentExpiredCode style={{
            justifyContent: loading? 'center': 'initial'
          }}>
            {loading? <Loading show type="large"/> : (
              <>
                <div>
                  <img src={expiredAccessImage} alt="error QrCode" className="data-cy-qrcode-details-error-image" />
                </div>
                <div>
                  <h2><strong className="data-cy-qrcode-details-something-went-wrong">Não foi possivel ler o QRcode</strong></h2>
                  <p className="data-cy-qrcode-details-contact-responsible" style={{ fontSize: '16px' }}>
                  Entre em contato com o responsável pelo local para maiores informações.
                  </p>
                </div>
              </>
            )}
          </ContentExpiredCode>
        </ColumnExpiredCode> 
        <ColumnExpiredCode  />
      </ContainerExpiredCode>    
    )
  }

  if(cancellActivate) {
    return (
      <Container style={loading ?{height: '100%'}:{}}>
        <ContentErrorQrCode>
          <div>
            <img src={erroTokenQrcode} alt="error QrCode" className="data-cy-qrcode-cancel-error-image"/>
          </div>
          <div>
            <h2><strong className="data-cy-qrcode-cancel-not-information-to-show">Não há informações para serem exibidas neste momento.</strong></h2>
          </div>
        </ContentErrorQrCode>
      </Container>
    )
  }

  if(!validCell && !configuration.anonymous) {
    if(!componentsIndex) {
      return <></>
    }

    return (
      <Container style={loading ?{height: '100%'}:{}}>
        <Content>
          {loading? <Loading show type="large" />:
          (
            <>
              {components[componentsIndex].component}
              <div className="buttons-bottom-container">
                <button className="data-cy-qrcode-cancel-button" onClick={() => {                  
                  if(componentsIndex === 2){
                    setComponentsIndex(1)
                  }
                  else{
                    setCancellActivate(true)  
                  }                  
                  }}>Cancelar</button>
                <button
                  className="data-cy-qrcode-confirm-button"
                  onClick={() => next()}
                >{componentsIndex === 1 ?'Confirmar':'Validar'}</button>
              </div>
            </>
          )}
        </Content>
      </Container>
    )
  }

  if(loading) {
    return (
      <Container style={{height: '100%'}}>
        <Content>
          <Loading show type="large" />
        </Content>
      </Container>
    )
  }

  return (
    <Container>
      <Content>
        {data && 
          data.attendanceTime && 
          JSON.stringify(data.attendanceTime) !== '{}' && 
          data.status !== 'CLOSED' && 
          data.status !== 'CANCELLED' && (
            <LoadTime>
              <div className="title data-cy-qrcode-attendance-time">
                Tempo de Atendimento
              </div>
              {showTime ? <Stopwatch /> : (
                <div className="time data-cy-qrcode-watch-time">
                  <Loading show type="small" />
                </div>
              )}
            </LoadTime>
          )
        }


        
        {cardRow(<FaCircle size={10} color="#ff7716" className="title data-cy-qrcode-protocol" />, 'Protocolo:', data.protocol)}
        {cardRow(<FaCircle size={10} color="#ff7716"  className="title data-cy-qrcode-requester"/>, 'Solicitante:', data.name)}
        {cardRow(<FaCircle size={10} color="#ff7716" className="title data-cy-qrcode-motive"/>, `Motivo ${data.type == 'INCIDENTE' ? 'do incidente':'da solicitação'}:`, data.motivo)}
        {cardRow(<FaCircle size={10} color="#ff7716" className="title data-cy-qrcode-situation"/>, 'Situação:', getStatus(data.status))}
        {cardRow(
          <FaList size={10} color="#ff7716" className="title data-cy-qrcode-history" />,
          'Histórico:',
          `${data.type == 'INCIDENTE' ? 'Esse incidente':'Essa solicitação'} possui ${data.history.length} registro(s).`,
          'link', 'history',
        )}
        {cardRow(<FaComment size={10} color="#ff7716" className="title data-cy-qrcode-title" />, ''+customTitle+':', data.title)}
        {cardRow(<FaComment size={10} color="#ff7716" className="title data-cy-qrcode-description"/>, ''+customDescription+':', data.description)}
        {cardRow(<FaClock size={10} color="#ff7716" className="title data-cy-qrcode-creation-date"/>, 'Data da criação:',
          data.data ? format(parseISO(data.data), 'dd/MM/yyyy HH:mm') : '')}
        {cardRow(<FaExclamationTriangle size={10} color="#ff7716" className="title data-cy-qrcode-criticality"/>, 'Criticidade:', getDangerousness(data.dangerousness))}
        {cardRow(<FaMapMarkerAlt size={10} color="#ff7716" className="title data-cy-qrcode-local"/>, 'Local:', data.localName)}
        {cardRow(
          <FaList size={10} color="#ff7716" className="title data-cy-qrcode-evidence"/>,
          'Evidências:',
          `${data.type == 'INCIDENTE' ? 'Esse incidente':'Essa solicitação'} possui ${data.images ? data.images.length : 0} evidência(s).`,
          data.images && data.images.length > 0 ? 'link' : '', 'evidence',
        )}
      </Content>
    </Container>
  );
}

export default Details;
