export function setOccurrence(data) {
  return {
    type: '@occurrence/CREATE_OCCURRENCE',
    payload: { data },
  };
}

export function resetOccurrence(data) {
  return {
    type: '@occurrence/RESET_OCCURRENCE',
    payload: { },
  };
}