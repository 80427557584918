import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types'

import { useSelector, useDispatch } from 'react-redux'

import { FaAngleDown, FaTimes } from 'react-icons/fa'

import { Container, Circle, Content, Title } from './styles';

import { resetOccurrence } from '../../store/modules/occurrence/actions'

import { changeFeedBack } from '../../store/modules/feedBack/actions'
function Feedback({ type, setStep, history }) {
  const dispatch = useDispatch();

  const configuration = useSelector(state => state.configuration.data);

  const { protocol, qr_code_user_id, anonymous } = useSelector(state => state.occurrence.data);

  const { typeCreated } = useSelector(state => state.feedBack);

  const [urlForDetails, setUrlForDetails] = useState({ protocol: '', qr_code_user_id: '' })  
  
  useEffect(() => {
    setUrlForDetails({ protocol, qr_code_user_id });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const icon = 
    type === 'error'
      ? 
        <Circle type={type}>
          <FaTimes className="data-cy-qrcode-incorrect-icon" size={50} color={'#ff4e4e'} /> 
        </Circle>
      : <Circle>
          <FaAngleDown className="data-cy-qrcode-correct-icon" size={50} color={'#0bd00b'} />
        </Circle>

  const title = type === 'error'
    ? `Erro ao cadastrar ${typeCreated.toLowerCase()}`
    : `${typeCreated === 'INCIDENTE' ? 'Incidente cadastrado' : 'Solicitação cadastrada'} com sucesso!`
        
  return ( 
    <Container>
      <Content>
        {icon}
        <Title className="data-cy-qrcode-title-message">
          {title}
        </Title>
        <div className="buttons-bottom-container">
          <button className="data-cy-qrcode-new-action" onClick={() => {
            dispatch(resetOccurrence())
            setStep(1)
            dispatch(changeFeedBack({
              show: false,
              message: '',
              type: null
            }))

          }}>Novo acionamento</button> 
          {configuration.follow && type === 'success' && !anonymous
            ? (
              <button className="data-cy-qrcode-go-to-details" onClick={() => {
                dispatch(changeFeedBack({
                  show: false,
                  message: '',
                  type: null
                }))
                history.push(`/details/${urlForDetails.protocol}`)
              }}>Ir para detalhes</button>            
          ) : null}  
          <button className="data-cy-qrcode-back-to-home" 
                  onClick={() => {
                dispatch(resetOccurrence())
                dispatch(changeFeedBack({
                  show: false,
                  message: '',
                  type: null
                }))
                history.push(`/?url=${configuration.url}`)
              }}>Voltar para home</button>       
          
          
        </div>
      </Content>
    </Container>
  );
}

Feedback.propTypes = {
  type: PropTypes.string.isRequired,
  setStep: PropTypes.func,
  history: PropTypes.func,
};

Feedback.defaultProps = {
  type: 'success',
  setStep: () => {},
  history: () => {
    return {
      push: () => {}
    }
  },
};


export default Feedback;
