import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { setEndpointError } from '../../../store/modules/endpointErrorSave/actions';
import { changeFeedBack } from '~/store/modules/feedBack/actions';

import { erroQrcode } from '../../../assets';

import { Container, Column, ContentErrorQrCode } from './styles';

export function Error400and500({ history }) {
  const dispatch = useDispatch();
  const { url } = useSelector(state => state.configuration.data);
  const { show } = useSelector(state => state.endpointErrorSave.data);
  const { background } = useSelector(state => state.configuration.data);

  if(!show) {
    return <></>
  }

  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: background,
        top: 0,
        left: 0,
        zIndex: 9999
      }}
    >
      <Container>
        <Column  />
        <Column >
          <ContentErrorQrCode>
            <div>
              <img src={erroQrcode} className="data-cy-qrcode-error-image" alt="error QrCode" />
            </div>
            <div>
              <h2><strong className="data-cy-qrcode-something-went-wrong" >Algo deu errado...</strong></h2>
              <p className="data-cy-qrcode-but-soon-will-be-resolved">
                Mas logo será resolvido, por favor, volte mais tarde.
              </p>
            </div>
            <div className='button' onClick={() => {
              dispatch(changeFeedBack({
                show: false,
                message: '',
                type: null
              }))
              dispatch(setEndpointError(false))
              history.push(`/?url=${url}`)
            }}>
              <button className="data-cy-qrcode-ok-button">Ok</button>
            </div>
          </ContentErrorQrCode>
        </Column> 
        <Column/>
      </Container>
    </div>
  );
}
