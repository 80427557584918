/* eslint-disable default-case */
/* eslint-disable no-unreachable */
import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';

import {
  FaUser,
  FaMapMarkerAlt,
  FaCheckCircle,
  FaTimesCircle,
  FaClock
} from 'react-icons/fa';

import {
  Container,
  Content,
  CardContent,
  CardSuccess,
  CardError
} from './styles';

import Loading from '~/Components/Loading';

import api from '~/services/api';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function TaskDetails({ history }) {
  let query = useQuery();
  const { url } = useSelector(state => state.configuration.data);
  const [taskList, setTaskList] = useState([]);
  const [laoding, setLaoding] = useState(true);
  const [taskInfo, setTaskInfo] = useState({
    id: null,
    duration: null,
    userName: "",
    local: {
      id: null,
      name: ""
    },
  });

  useEffect(() => {
    const code = query.get("code");

    async function init() {
      let decode = atob(code);
      decode = JSON.parse(decode);

      const { assignmentHistoryId, localId } = decode;

      try {
        const { data } = await api.get(`AssignmentChecklist/getProceduresPerformed/${localId}/${assignmentHistoryId}`)
     
        const { item, ...rest } = data

        setTaskList(item);
        setTaskInfo(rest);
        setLaoding(false);
      } catch(error) {
        toast.error(
          error &&
          error.response &&
          error.response.data &&
          error.response.data.Message? error.response.data.Message : 'Ocorreu um erro!');

        setTimeout(() => {
          history.goBack()
        }, 4000)
      }
    }

    if(code) {
      init();
    } else {
      history.push(`/?url=${url}`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getTime(value) {
    let minutes = Math.floor(value / 60);
    let seconds = value - minutes * 60;

    let respone = `${minutes}m${seconds}seg`

    return respone
  }

  if(laoding) {
    return (
      <Container>
        <Content>
          <Loading show={laoding} type="large" />
        </Content>
      </Container>
    )
  } 

  return (
    <Container>
      <Content>
        <div className="content">
          <div>
            Veja o(s) procedimento(s) executado(s):
          </div>
          <div>
            <FaMapMarkerAlt color="#fe7013" size={18} />
            <span>{taskInfo.local.name}</span>
          </div>
          <CardContent>
            {taskList.map((res,index) => {
              if(res.done) {              
                return (
                  <CardSuccess key={index+'_item'}>
                    <div>
                      <FaCheckCircle color="#7fd364" size={'2em'} />
                    </div>
                    <div>{res.name}</div>
                  </CardSuccess>
                )
              } else {
                return (
                  <CardError>
                    <div>
                      <FaTimesCircle color="red" size={'2em'} />
                    </div>
                    <div>
                      <span>{res.name}</span>
                      <span>{res.justification}</span>
                    </div>
                  </CardError>
                )
              }
            })}
          </CardContent>
        </div>
        <div className="bottom-container">
          <div>
            <div>
              <FaUser color="#7a61d9" size={16} />
              <span>{taskInfo.userName}</span>
            </div>
            {taskInfo.duration && (
              <div>
                <FaClock style={{ color: '#fe7013' }} size={16}/>{getTime(taskInfo.duration)}
              </div>
            )}
          </div>
          <div className="button">
            <button onClick={() => {
              history.goBack()
            }}>Voltar</button>
          </div>
        </div>
      </Content>
    </Container>
  );
}

export default TaskDetails;
