import React from "react"

import { Container, Content } from './styles';
import { FaTimes } from 'react-icons/fa'

export const ModalWithContent = ({title, width, height, closeFunction, ...props}) => {
    return ( 
        <>
          <Container>
            <Content width={width} height={height}>
              <button onClick={() => {
                closeFunction()
              }}>
                <FaTimes className='data-cy-qrcode-img-close' color="#eb8080" size={22} />
              </button>
              <div className="header">
                { title }
              </div>
              <div className="message">
              {props.children}
              </div>
            </Content>
          </Container>
        </>
      );

}