import React from 'react';

import PropTypes from 'prop-types';

import { FaTimes } from 'react-icons/fa'

import OwlCarousel from 'react-owl-carousel';

import { Container, NotImage } from './styles';

import { camImage } from '~/assets'
function Carousel({ midia_files, removeItem }) {
  
  if(midia_files.length < 1) {
    return (
      <Container>
        <NotImage>
          {/* eslint-disable-next-line jsx-a11y/img-redundant-alt*/}
          <img src={camImage} alt="cam image" />
        </NotImage>
      </Container>
    )
  }
 
  return(
    <Container>
      <OwlCarousel 
        items={1} 
        nav 
        dots 
        mouseDrag={true} 
        height={400}
        margin={8}
      >
        {midia_files.map((file, i) => {
          return (
            <div className="file" key={'item-'+i}>
              <button className="data-cy-qrcode-close-file" onClick={() => removeItem(i)}>
                <FaTimes color="#fff" size={26} />
              </button>
              <img className={"data-cy-qrcode-img-"+i} src={file} alt="" />
            </div>
          );
        })}
      </OwlCarousel>     
    </Container>
  );
}


Carousel.propTypes = {
  midia_files: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  removeItem: PropTypes.func.isRequired
};

Carousel.defaultProps = {
  midia_files: [],
  removeItem: () => {}
};


export default Carousel;
