import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from '@rocketseat/unform';

function One() {
  const { data } = useSelector((state) => state.detailsOccurrence);
  const code = useSelector(state => state.configuration.data.code)
  const type = data.type
 
  return (
    <Form onSubmit={() => {}}>
      <div>
        <label className="data-cy-qrcode-intermediate-text">Os detalhes de {type == 'INCIDENTE' ? 'um incidente' : 'uma solicitação'} só podem ser visualizados pelo seu Solicitante
          e, esta por sua vez, foi aberta pelo usuário que possui o número de celular {code}.
          Este seria você?</label>
      </div>
    </Form>
  );
}

export default One;
