export function setConfig(data) {
  return {
    type: '@configuration/SET_CONFIGURATION',
    payload: { data },
  };
}

export function setLoading(data) {
  return {
    type: '@configuration/SET_CONFIGURATION_LOADING',
    payload: { data },
  };
}

export function resetConfig(data) {
  return {
    type: '@configuration/RESET_CONFIGURATION',
    payload: { },
  };
}
