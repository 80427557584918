/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
/* eslint-disable no-unreachable */
import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import {  useSelector } from 'react-redux';

import { parseISO, format } from 'date-fns';

import {
  FaEye,
  FaClock,
} from 'react-icons/fa';

import { toast } from 'react-toastify'

import {
  Container,
  Content,
  Card,
  CardItemLeft,
  CardItemRight,
  OpenStatus,
  OngoingStatus,
  CancelledStatus,
  PendentStatus,
  RefusedStatus,
  OtherStatus,
  ForwardedStatus,
  ReopenedStatus
} from './styles';

import Loading from '~/Components/Loading';

import api from '~/services/api';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function List({ history }) {
  const [taskList, setTaskList] = useState([{assignmentHistory: []}]);
  const [laoding, setLaoding] = useState(true);
  const { url } = useSelector(state => state.configuration.data);
  let query = useQuery();

  useEffect(() => {
    const code = query.get("code");

    async function init() {
      try {
        let decode = atob(code);
        decode = JSON.parse(decode);

        const { localId } = decode;

        const reponse = await api.get(`serviceScheduler/getHistoryOfActivitiesPerformed/${localId}`)

        const data = [];
        
        reponse.data.map(response => {
          return response.assignmentHistory.map((res, index) => {
            return data.push({ ...res, name: response.name })
          })
        })

        setTaskList(data.sort((a, b) => {
          if (a.scheduledTime < b.scheduledTime) {
              return 1;
          }

          return -1;
        }));

        setLaoding(false)
      } catch(error) {
        toast.error(
          error &&
          error.response &&
          error.response.data &&
          error.response.data.Message? error.response.data.Message : 'Ocorreu um erro!');

        setTimeout(() => {
          history.goBack()
        }, 4000)
      }
    }

    if(code) {
      init();
    } else {
      history.push(`/?url=${url}`);
    }
  }, [])

  function getStatus(data) {
    //nao possui em ocorrencia: pausado, nao realizado, atrasado, perdido
    if (data.toUpperCase() == 'OPEN') {
      return <OpenStatus>ABERTO</OpenStatus>;
    } else if (data.toUpperCase() == 'ONGOING') {
      return <OngoingStatus>EM ANDAMENTO</OngoingStatus>;
    } else if (data.toUpperCase() == 'CANCELLED') {
      return <CancelledStatus>CANCELADO</CancelledStatus>;
    } else if (data.toUpperCase() == 'DONE') {
      return <RefusedStatus>FINALIZADO</RefusedStatus>;
    } else if (data.toUpperCase() == 'PENDENT') {
      return <PendentStatus>PENDENTE</PendentStatus>;
    } else if (data.toUpperCase() == 'REFUSED') {
      return <RefusedStatus>RECUSADO</RefusedStatus>;
    } else if (data.toUpperCase() == 'CLOSED') {
      return <RefusedStatus>FECHADO</RefusedStatus>;
    } else if (data.toUpperCase() == 'REOPENED') {
      return <ReopenedStatus>REABERTO</ReopenedStatus>;
    } else if (data.toUpperCase() == 'FORWARDED') {
      return <ForwardedStatus>ENCAMINHADO</ForwardedStatus>;
    } else {
      return <OtherStatus>{data}</OtherStatus>;
    }
  }
  
  function getMouth(mes) {
    switch (mes) {
      case '01':
        mes = 'Jan';
        break;
      case '02':
        mes = 'Fev';
        break;
      case '03':
        mes = 'Mar';
        break;
      case '04':
        mes = 'Abr';
        break;
      case '05':
        mes = 'Mai';
        break;
      case '06':
        mes = 'Jun';
        break;
      case '07':
        mes = 'Jul';
        break;
      case '08':
        mes = 'Ago';
        break;
      case '09':
        mes = 'Set';
        break;
      case '10':
        mes = 'Out';
        break;
      case '11':
        mes = 'Nov';
        break;
      case '12':
        mes = 'Dez';
        break;
    }
    return mes;
  };

  function getStartToEnd(params) {
    let time = params && params.time ? params.time: false;

    if(time) {
      return format(parseISO(time.startTime), 'HH:mm') + ' às ' + 
        format(parseISO(time.endTime), 'HH:mm')
    }

    return ''
  }

  function getTimeScheduler(schedulerTime) {
    return schedulerTime && format(parseISO(schedulerTime), 'HH') + 'h' + 
    format(parseISO(schedulerTime), 'mm') + 'min'
  }

  async function setLog(localId, assignmentHistoryId) {
    try {
      await api.post(`Assignment/createLogAssignmentQrCodeHistory/${localId}/${assignmentHistoryId}`);
    } catch(err) {

    }
  }

  if(laoding) {
    return (
      <Container>
        <Content>
          <Loading show={laoding} type="large" />
        </Content>
      </Container>
    )
  }
  
  return (
    <Container>
      <Content>
        <div className="content">
          <div className="title">Histórico de atividades:</div>
          {taskList.map((res, index) => {
              return (
                <Card key={index+'_item'}>
                  <CardItemLeft w={'60px'}>
                    <span>{res.scheduledTime && format(parseISO(res.scheduledTime), 'dd')}</span> 
                    <span>{res.scheduledTime && getMouth(format(parseISO(res.scheduledTime), 'MM'))}</span> 
                  </CardItemLeft>
                  <CardItemRight>
                    <div>
                      <div>
                        <strong>Programado {res.scheduledTime && getTimeScheduler(res.scheduledTime)}</strong> - {res.status && getStatus(res.status)}
                      </div> 
                      <div>
                        <button
                          onClick={() => {
                            if(res.id) {
                              const code = query.get("code");
                              let decode = atob(code);
                              decode = JSON.parse(decode);
                        
                              const { localId } = decode;
  
                              setLog(localId, res.id);
  
                              history.push(`/tasksperformed/details?code=${btoa(JSON.stringify({
                                localId,
                                assignmentHistoryId: res.id,
                                random: new Date()
                              }))}`)
                            }
                          }}
                        >
                          <FaEye style={{ color: '#40BBCA' }} size={20} />
                        </button>
                      </div>
                    </div>
                    <div>{res.name}</div>
                    <div><FaClock style={{ color: '#fe7013' }} size={16}/> {getStartToEnd(res)}</div>
                  </CardItemRight>
                </Card>
              )
          })}
        </div>
        <div className="buttons-bottom-container">
          <button onClick={() => {
            history.goBack();
          }}>Voltar</button>
        </div>
      </Content>
    </Container>
  );
}

export default List;
