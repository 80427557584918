// eslint-disable prettier/prettier
import React from 'react';

import { useSelector } from 'react-redux'

import PropTypes from 'prop-types';

import { Container } from './styles';
export default function Header() {
  const { logo } = useSelector(state => state.configuration.data);
  const heightDefault = useSelector(state => state.configurationSystem.height_default_of_layout_default);

  if(heightDefault) {
    return (
      <Container> 
          <img src={logo} alt="Carregando..." className="data-cy-qrcode-image"/>
      </Container>
         
    );
  } else {
    return <></>
  }
}

Header.propTypes = {
  shadow: PropTypes.bool,
};

Header.defaultProps = {
  shadow: false,
};
