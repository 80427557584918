import produce from "immer";

const INITIAL_STATE = {
  data: {
    step1: {
      valid: false,
      key: 'step1',
    },
    step2: {
      valid: false,
      key: 'step2',
    },
    step3: {
      valid: false,
      key: 'step3',
    },
    step4: {
      valid: false,
      key: 'step4',
    },
    step5: {
      valid: false,
      key: 'step5',
    },
  }
}

export default function validButtonState(state=INITIAL_STATE, action) {
  return produce(state, draft=>{
    switch(action.type) {
      case '@navigationButton/SET_VALIDATION': {
        draft.data = action.payload.data
        break        
      }
      case '@navigationButton/RESET_VALIDATION': {
        draft.data = INITIAL_STATE
        break
      }
      default:
    }
  })
}