import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: grid;
  justify-content: space-around;
  width: 100%;
  /* padding-left: 12px;
  padding-right: 12px; */
  /* background: #fff; */
  grid-template-columns: 
    25% 
    50%
    25%;
  padding: 10px;

  box-sizing: border-box !important;

   @media only screen and (max-width: 800px) {
    display: grid;
    flex-direction: column;
    grid-template-columns: 
    2% 94% 2%
  } 
`;

export const Column = styled.div`
  display: flex;
  flex-grow: 1;
  /* height: 100%; */
  justify-content: center;
  align-content: center;
  align-items: center;

  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const Content = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: #f8fdf5;
    /* background: #ffffff8c; */
    box-shadow: 0px 0px 4px 0px #00000024;
    flex-direction: column;
    padding: 10px 20px;
    justify-content: space-around;
    position: relative;

    > .loading {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background: #fff,
      border-radius: 4;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    > .content {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: space-around;
      position: relative;
      opacity: ${({ opacity }) => opacity?0:1};
    
      > div {
        display: flex;
        flex-direction: column;
    
        > a, button {
          border: none;
          border-radius: 50px;
          padding: 16px 0px;
          font-size: 16px;
          background: linear-gradient(90deg, rgba(104,153,255,1) 35%, rgba(160,96,187,1) 100%); 
          color: #fff;
          opacity: 1;
          text-align: center;
    
          &:disabled {
            opacity: 0.6;
          }
    
          &:active {
            transition: 0.2s;
            opacity: 0.6;
          }
        }
    
        > a + a, button + button, button + a, a + button {
          margin-top: 10px;
        }
      }
    }
  `;

export const ContentErrorQrCode = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: #f8fdf5;
  /* background: #ffffff8c; */
  box-shadow: 0px 0px 4px 0px #00000024;
  flex-direction: column;
  padding: 10px 20px;
  /* justify-content: center; */

  > div:nth-child(1) {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  > div:nth-child(2) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-grow: 1; */
    flex-direction: column;
    margin-top: 15%;

    > h2 {
      text-align: center;
      
      > strong {
        text-align: center;
        color: #333;
      }
    }

    > p {
      padding: 12px 0px;
      font-size: 16px;
      color: #555;
      text-align: center;
    }
  }

  > .button {
    width: 100%;
    display: flex;
    justify-content: center;

    > button {
      width: 200px;
      padding: 8px 0px;
      border: none;
      border-radius: 10px;
      background: linear-gradient(90deg, rgba(104,153,255,1) 35%, rgba(160,96,187,1) 100%);
      color: #fff;
    }
  }
`;

export const ContentLogo = styled.div`
  height: 100px;
  justify-content: center;
  align-items: center;

  > img {
    max-height: 100%;
    max-width: 100%;
  }
`;

export const Salutation = styled.h2`
  display: flex;
  flex-direction: column;
`;
