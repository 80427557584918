import React, { useState, useEffect } from 'react';

import { Form, Input } from '@rocketseat/unform';

import { toast } from 'react-toastify'

import { useDispatch, useSelector } from 'react-redux';

import { Container } from './styles';

import { setOccurrence } from '~/store/modules/occurrence/actions'

import { setValidation } from '~/store/modules/validButton/action';

import InputPhone from '~/Components/InputPhone'

function StepTwo() {
  const dispatch = useDispatch();
  const occurrence = useSelector(state => state.occurrence);
  const validButton = useSelector(state => state.validButtonState) 
  const [validPhone, setValidPhone] = useState(true)
  const [valid, setInvalid] = useState(false)
  const { data } = useSelector(state => state.occurrence);

  useEffect(() => {
    const nomeSobrenome = /\b[A-Za-zÀ-ú][A-Za-zÀ-ú]+,?\s[A-Za-zÀ-ú]{1,19}\b/gi;    
    dispatch(setValidation({
      ...validButton.data, 
      step2: {
        ...validButton.data.step2,
        valid:(nomeSobrenome.test(data.name) && data.name.length > 2 && data.validPhone)
      }
    }))        
  }, [data.name, data.validPhone])
  
  return (  
    <Container>
      <Form onSubmit={() => {}}>
        <div>
          <label className="data-cy-qrcode-report-name-fone">Informe o nome e telefone:</label>
          <Input
            className="data-cy-qrcode-name"
            type="text"
            name="nome"
            placeholder="Nome e Sobrenome"
            value={occurrence.data.name.replace(/[0-9]/, '')}
            autoComplete="off"
            onBlur={(e) => {
              const nomeSobrenome = /\b[A-Za-zÀ-ú][A-Za-zÀ-ú]+,?\s[A-Za-zÀ-ú]{1,19}\b/gi;

              if(!(nomeSobrenome.test(e.target.value))){
                setInvalid(true)      
                toast.error('Nome ou sobrenome inválidos!') 
              }else{
                setInvalid(false)      
              }
            }}
            onChange={e => dispatch(setOccurrence({...occurrence.data, name: e.target.value.toUpperCase()}))}
          />
          {valid && (<span className="error">Nome ou sobrenome inválidos</span>)}
        </div>
        <div className="data-cy-qrcode-fone">
          <InputPhone            
            data={occurrence}
            validPhone={validPhone}
            setValidPhone={setValidPhone}
            setData={(res) => dispatch(setOccurrence(res))}
          />
        </div>
      </Form>
    </Container>
  );
}

export default StepTwo;
