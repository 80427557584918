import styled from 'styled-components';

import scroll from 'react-perfect-scrollbar';

export const Scroll = styled(scroll)`
  height: 100%;
  width: 100%;
  background: ${({ background }) => background ?background:'#599bcb'};
`;

export const Wrapper = styled.div`
  height: ${({ heightDefault }) => heightDefault ?'80%':'100%'};
  /* position: fixed; */
  width: 100%;
  /* top: 0;
  left: 0; */
`;
