import styled from 'styled-components';

export const Container = styled.div`  
  height: 100%;

  .owl-prev, .owl-next {
    color: #999 !important;
  }

  > div {
    > label {
      font-weight: 600;
      color: #333;
    }
  }

  > div.buttons-bottom-container {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    justify-content: space-between;

    > button {
      border: none;
      border-radius: 50px;
      padding: 16px 0px;
      font-size: 16px;
      background: linear-gradient(90deg, rgba(104,153,255,1) 35%, rgba(160,96,187,1) 100%); 
      color: #fff;
      opacity: 1;
      width: 100%;

      &:disabled {
        opacity: 0.6;
      }

      &:active {
        transition: 0.2s;
        opacity: 0.6;
      }
    }
  }
   div.buttons-gallery-camera-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0px 10px;

    > button {
      border: none;
      border-radius: 50px;
      padding: 16px 0px;
      font-size: 16px;
      background: linear-gradient(90deg, rgba(104,153,255,1) 35%, rgba(160,96,187,1) 100%); 
      color: #fff;
      opacity: 1;
      width: calc(50% - 20px);

      &:disabled {
        opacity: 0.6;
      }

      &:active {
        transition: 0.2s;
        opacity: 0.6;
      }
    }
  }
`;

export const ModalLabel = styled.span`
    width: 100%;
    text-align: center;    
    display: block;
    margin-bottom: 20px;
    color: #777;`
