import produce from 'immer';

const INITIAL_STATE = {
  data: {
    show: false,
    accepted: false
  }
};

export default function LGPD(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@LGPD/SET_LGPD': {
        draft.data = action.payload.data;        
        break;
      }
      default:
    }
  });
}
