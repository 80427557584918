/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import OwlCarousel from 'react-owl-carousel';

import { FaTimes } from 'react-icons/fa';

import { Container, Closed } from './styles';

function FullResolution({ images, startPosition, setShow }) {
  useEffect(() => {
    window.onkeydown = function(event) {
      if (event.keyCode === 27) {
        setShow(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Closed className="data-cy-qrcode-close-image"
        onClick={() => {
          // setImagePublication([]);
          setShow(false);
        }}
      >
        <FaTimes color="#ff4e4e" size={33} />
      </Closed>
      <OwlCarousel
        startPosition={startPosition}
        items={1}
        style={{ maxHeight: '100%', maxWidth: '100%' }}
        className="owlC"
      >
        {images.map((item, index) => {
          return (
            <img
              key={'item-'+index}
              src={item.image}
              className="viewing-full-images"
              alt="publication image"
            />
          );
        })}
      </OwlCarousel>
    </Container>
  );
}

export default FullResolution;

FullResolution.propTypes = {
  image: PropTypes.string.isRequired,
  startPosition: PropTypes.number,
  setShow: PropTypes.func,
};

FullResolution.defaultProps = {
  image: '',
  startPosition: 0,
  setShow: () => {},
};
