import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { FaTimes } from 'react-icons/fa'

import { Cover, Container, Content } from './styles';

import { setLGPD } from '~/store/modules/LGPD/actions'

function LGPD({ callback }) {
  const dispatch = useDispatch();
  const [checkbox, setCheckBox] = useState(false)
  const lgpd = useSelector(state => state.lgpd.data);
       
  return ( 
    <>
      <Container>
        <Content>
          <button onClick={() => {
            dispatch(setLGPD({ ...lgpd, show: false }))
          }}>
            <FaTimes color="red" size={22} />
          </button>
          <div className="header">
            Fique por dentro:
          </div>
          <div className="message">
            Ao continuar você compartilhará seus dados em nossa plataforma e, assim, concordará
            com nossos <a href="https://institucional.nicbrain.com.br/termos-de-uso/" target="_blank">Termos de Uso</a> e{' '}
            <a href="https://institucional.nicbrain.com.br/politica-de-privacidade/" target="_blank">Política de Privacidade</a>.
          </div>
          <div className="buttons-bottom-container">
            <div className="checkbox">
              <input className="data-cy-qrcode-read-accept" type="checkbox" checked={checkbox} onClick={() => {
                setCheckBox(!checkbox)
              }} />
              Li e aceito
            </div>
            <button className={!checkbox? "data-cy-qrcode-step-one-button-continue-disable" : "data-cy-qrcode-step-one-button-continue-active" } disabled={!checkbox} onClick={() => callback()}>Continuar</button>
          </div>
        </Content>
      </Container>
    </>
  );
}


export default LGPD;
