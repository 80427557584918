import styled from 'styled-components';

export const Container = styled.div`
  height: ${({ height }) => height ? height : '100%'};
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 20px;
  box-sizing: border-box !important;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  height: 100%;
  background: #ffffffc9;
  border-radius: 4px;
  box-shadow: 0px 0px 4px 0px #00000024;
  flex-direction: column;
  padding: 20px;
  /* justify-content: space-around; */
  width: 60%;

  @media only screen and (max-width: 800px) {
    width: 100%;
  }

  > div.buttons-bottom-container {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    justify-content: space-between;

    > button {
      border: none;
      border-radius: 50px;
      padding: 16px 0px;
      font-size: 16px;
      background: linear-gradient(90deg, rgba(104,153,255,1) 35%, rgba(160,96,187,1) 100%); 
      color: #fff;
      opacity: 1;
      width: 45%;
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 51px;

      &:disabled {
        opacity: 0.6;
      }

      &:active {
        transition: 0.2s;
        opacity: 0.6;
      }
    }
  }
`;

export const Title = styled.h2``
