import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100%;
  display: flex;
  width: 100%;
  padding: 20px;
  /* align-items: center; */
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  min-height: 100%;
  background: #ffffffc9;
  border-radius: 4px;
  box-shadow: 0px 0px 4px 0px #00000024;
  flex-direction: column;
  padding: 10px 20px;
  /* justify-content: space-around; */
  width: 60%;

  @media only screen and (max-width: 800px) {
    width: 100%;
  }

  > * + * {
    margin-top: 8px;
  }

  > .content_history {
    flex-grow: 1;
  }

  
  > div.buttons-bottom-container {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    justify-content: space-between;
    padding: 0px 0px;

    > button {
      border: none;
      border-radius: 50px;
      padding: 16px 0px;
      font-size: 16px;
      background: linear-gradient(90deg, rgba(104,153,255,1) 35%, rgba(160,96,187,1) 100%); 
      color: #fff;
      opacity: 1;
      width: 100%;

      &:disabled {
        opacity: 0.6;
      }

      &:active {
        transition: 0.2s;
        opacity: 0.6;
      }
    }
  }
`;


export const Title = styled.h2`
  color: #333;
`

export const Card = styled.div``


export const OpenStatus = styled.span`
  color: red;
  font-weight: normal;
`;

export const OngoingStatus = styled.span`
  color: #ffc107;
  font-weight: normal;
`;

export const CancelledStatus = styled.span`
  color: #dc3545;
  font-weight: normal;
`;

export const FinishedStatus = styled.span`
  color: #007bff;
  font-weight: normal;
`;

export const PendentStatus = styled.span`
  color: #6c757d;
  font-weight: normal;
`;

export const ForwardedStatus = styled.span`
  color: blue;
  font-weight: normal;
`;

export const RefusedStatus = styled.span`
  color: black;
  font-weight: normal;
`;

export const OtherStatus = styled.span`
  color: black;
  font-weight: normal;
`;

export const ReopenedStatus = styled.span`
  color: #c617c6;
  font-weight: normal;
`;