import React from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';
function Loading({ show, type }) {
  if(!show) {
    return <></>
  }
  
  return (
    <Container type={type} >
      <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    </Container>
  );
}

Loading.propTypes = {
  show: PropTypes.bool,
  type: PropTypes.string, 
};

Loading.defaultProps = {
  show: false,
  type: 'large'
};

export default Loading;
