import produce from 'immer';

const INITIAL_STATE = {
  data: {
    show: false,
    title: '',
    description: '',
    name: "Sistema",
    subModuleId: '0',
    email: "kobel@kobel.com.br",
  }
};

export default function endpointErrorSave(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@endpointError/SET_ENDPOINT_ERROR': {
        draft.data = action.payload.data;
        break;
      }
      case '@endpointError/RESET_ENDPOINT_ERROR': {
        draft.data = INITIAL_STATE
        break;
      }
      default:
    }
  });
}
