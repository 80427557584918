import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'react-toastify'

import { Container, ModalLabel } from './styles';

import Carousel from './Components/Carousel'

import Loading from '~/Components/Loading'

import { uploadOptions } from '~/Scripts/'

import { setOccurrence } from '~/store/modules/occurrence/actions'

import { setValidation } from '~/store/modules/validButton/action';

import { resize } from '~/Scripts'

import { ModalWithContent } from '~/Components/ModalWithContent';


function StepFourth({ setItensStepFourth }) {
  const [option, setOption] = useState(null);
  const [midiaFiles, setMidiaFiles] = useState([]);
  const [show, setShow] = useState(false);

  const [showModal, setShowModal] = useState(false)

  const dispatch = useDispatch();
  const occurrence = useSelector(state => state.occurrence)
  const { data } = useSelector(state => state.occurrence)
  const validButton = useSelector(state => state.validButtonState) 

  useEffect(() => {
    // const uagent = navigator.userAgent.toLowerCase()

    if(option !== null) {
      if(option === 1) {
        setTimeout(() => {
          const inputfile = document.getElementById('new_file');
          //alert(JSON.stringify(uagent))
          /*if(uagent.search("iphone") > -1) {
            inputfile.ontouchstart()
          } else {*/
            inputfile.click();
          //}
        }, 1000)
      } else {
        setTimeout(() => {
          const inputfile = document.getElementById('new_file_camera');
          //alert(JSON.stringify(uagent))
          /*if(uagent.search("iphone") > -1) {
            inputfile.ontouchstart()
          } else {*/
            inputfile.click();
          //}
        }, 1000)
      }

      setOption(null);
    }
  }, [option])

  useEffect(() => {            
    dispatch(setValidation({
      ...validButton.data, 
      step5: {
        ...validButton.data.step5,
        valid: !(data.photoEnforced && 
        data.photoEnforcedType !== 'Closed' && 
        data.image.length < 1)
      }
    }))        
  }, [data.image])  

  async function fileChange(element) {
    const { files } = element;
  
    const imageUpload = midiaFiles;
    const filePromisses = [];
    let error = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      // Only pics
      if (!file.type.match('image')) continue;
      
      if(file.size > 1000000) {
        error.push(file.size)
        filePromisses.push(
          new Promise(async resolve => {
            resize(file, 500*1024, 1000, Infinity, 0.9, blob => {
              const picReader = new FileReader();
  
              picReader.addEventListener('load', async event => {                
                return resolve(event.target.result);
              });
              picReader.readAsDataURL(blob);
            });
          })
        );
      } else {
        // eslint-disable-next-line no-loop-func
        filePromisses.push(
          new Promise(async resolve => {
            const picReader = new FileReader();

            picReader.addEventListener('load', async event => {
              const picFile = event.target;
              // imageUpload.push(picFile.result);  
              resolve(picFile.result);
            });
            picReader.readAsDataURL(file);
          })
        );
      }

      if(i >= 3) {
        console.log('i', i)
        toast.info('Uma ou mais fotos não foram adicionadas por exceder a quantidade máxima!');

        break;
      }

    }

    if(error.length > 0) {
      toast.info('Uma ou mais imagens foram campactadas por exceder o valor de 1mb!');
    }
  
    await Promise.all(filePromisses).then(async value => {
      setMidiaFiles([...imageUpload, ...value])
    });
  }

  useEffect(() => {
    setShow(false);

    setTimeout(() => {
      setShow(true);
    }, [500])
  }, [midiaFiles])

  useEffect(() => {
    dispatch(setOccurrence({
      ...occurrence.data, 
      image: midiaFiles.map(res => {
        return {
          Type: "image/base64",
          Data: res.split('base64,')[1]
        }
      })
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [midiaFiles])

  function removeItem(index) {
    let itens = midiaFiles

    setMidiaFiles(itens.filter((res, i) => i !== index))
  }

  if(!show) {
    return <Loading show={true} />
  }

  return (  
    <>
      <Container>
        <div>
          <label className="data-cy-qrcode-input-4-files">{`Se necessário adicione até 4 arquivos a ${occurrence.data.type =='INCIDENTE' ? 'este incidente' : 'esta solicitação'}:`}</label>
        </div>
        <Carousel midia_files={midiaFiles} removeItem={removeItem} />
        <input         
          type="file" 
          name="envidence[]" 
          id="new_file" 
          accept="image/*"
          style={{ display: navigator.userAgent.toLowerCase().search("iphone") > -1 ? '' : 'none', marginTop: 20 }} 
          onChange={e => fileChange(e.target)}
          multiple={true}
          maxLength={4}
        />
        <input         
          type="file" 
          accept="image/*" 
          id="new_file_camera"
          capture="camera"
          name="envidence[]"
          style={{
            display: 'none' 
          }}
          multiple={true}
          maxLength={4}
          onChange={e => fileChange(e.target)}
        />
        {
          !(navigator.userAgent.toLowerCase().search("iphone") > -1) &&
          <div className="buttons-bottom-container">
            <button
              disabled={midiaFiles.length > 3}
              onClick={() => {
                //uploadOptions(setOption);
                setShowModal(true)
              }}
              className="data-cy-qrcode-insert-button"
            >Inserir</button>
          </div>
        }

        
        
      </Container>
      {
        showModal &&
        <Container>
        <ModalWithContent 
          title={'Atenção!'}
          width={'300px'}
          height={'200px'}
          closeFunction={() => {setShowModal(false)}}
        > 
          <ModalLabel data-cy-qrcode-obtain-image>De onde gostaria de obter as imagens?</ModalLabel>
          <div className="buttons-gallery-camera-container">
          <button className="data-cy-qrcode-button-gallery" 
            onClick={() => {
              setOption(1)
              setShowModal(false)
              }}>Galeria</button>
          
          <button className="data-cy-qrcode-button-camera" 
          onClick={ () => {
              setOption(2)
              setShowModal(false)
            }}>Câmera</button>
          </div>
        </ModalWithContent>
        </Container>
      }
    </>
  );
}

export default StepFourth;
