import styled from 'styled-components';

export const Container = styled.div`  
    height: 100%;

  > form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    height: 80%;

    > div {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      > label {
        font-weight: 600;
        color: #333;
      }

      > input {
        margin-top: 10px;
        padding: 15px;
        border: none;
        border-radius: 50px;
        background: transparent;
        font-size: 16px;
        width: 100%;
        color: #333;
        font-weight: 500;

        > &::placeholder {
          color: #777 !important;
        }
        > &:invalid {
          color: gray;
        }
      } 

      > .select {
        display: flex;
        border-radius: 50px;
        background: #fff;
        padding-right: 0px;
        margin-top: 10px;       
          
        select {
          overflow: hidden;
          white-space: pre;
          text-overflow: ellipsis;
          -webkit-appearance: none;
          padding: 15px;
          border: none;
          border-radius: 50px;
          background: transparent;
          font-size: 16px;
          width: 100%;
          color: #333;
          font-weight: 500;

          > &::placeholder {
            color: #777 !important;
          }

          > &:invalid {
            color: gray;
          }
        }
      }
    }
  }
`;

export const Content = styled.div`  
  top: 10%;
  margin-top: 10% !important;

  > div.level-of-periculosidade {
    display: flex;
    flex-direction: row; 
    justify-content: space-around; 
    margin-top: 20px;

    > button {
        border: none;
        background: transparent;
      > div {
        display: flex;
        flex-direction: column;
      }
    }
  }
`
