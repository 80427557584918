import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { 
  Container, 
  Content, 
  Title, 
  Thumbnail,
  TitleStatus,
  ResponseName
} from "./styles";

import FullResolution from './FullResolution';


function getStatus(data) {
  if (data.toUpperCase() === 'OPEN') {
    return 'ABERTURA';
  } else if (data.toUpperCase() === "ONGOING") {
    return 'INFO ADICIONAL';  
  } else if (data.toUpperCase() === "CLOSED") {
    return 'FECHAMENTO';
  }  
}

function History({ history }) {
  const [showFullResolution, setShowFullResolution] = useState(false);
  const [startPosition, setStartPosition] = useState(0);  
  const { data } = useSelector(state => state.detailsOccurrence)
  const { protocolAcessForDetails, user_id } = useSelector(state => state.detailsOccurrence.data)

  // const midia_files = [
  //   {
  //     thumbnail: teste1,
  //     image: teste1
  //   },
  // ]

  if (showFullResolution) {
    return (
      <FullResolution
        images={data.images.map((v) => {
          return{
            ...v,
            image: 'data:image;base64,' + v.image
          }  
        })}
        startPosition={startPosition}
        setShow={setShowFullResolution}
      />
    );
  }

  return (
    <Container>
       <Content>
          <div className="content">
            <Title className="data-cy-qrcode-evidence-title">
              Evidências 
            </Title>
            <div className="content_images">
              {data.images.map((item, index) => {
                return (
                  <Thumbnail key={'item-'+index}>
                    <div>
                      <TitleStatus className={"data-cy-qrcode-evidence-" +item.typeStatus}>
                        {getStatus(item.typeStatus)}
                      </TitleStatus>
                      {/*<ResponseName>
                        {item.createByName|| ""}
                      </ResponseName>*/}
                    </div>
                    <div
                      onClick={() => {
                        setStartPosition(index);
                        setTimeout(() => {
                          setShowFullResolution(true);
                        }, 300);
                      }}
                    >
                      <img className={'data-cy-qrcode-image-'+index}  src={ 'data:image;base64,' + item.thumbnail} alt="" />
                    </div>
                  </Thumbnail>
                );
              }).reverse()
              }
            </div>
          </div>
          {
            protocolAcessForDetails &&
            <div className="buttons-bottom-container">
              <button className="data-cy-qrcode-evidence-back-button"
                     onClick={() => {
                history.push(`/details/${protocolAcessForDetails}`)
              }}>Voltar</button>
            </div>
          }
         
        </Content>
    </Container>
  );
}

export default History;
