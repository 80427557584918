import { combineReducers } from 'redux';

import occurrence from './occurrence/reducer';
import configuration from './configuration/reducer';
import feedBack from './feedBack/reducer';
import detailsOccurrence from './detailsOccurrence/reducer';
import lgpd from './LGPD/reducer';
import configurationSystem from './configurationSystem/reducer';
import endpointErrorSave from './endpointErrorSave/reducer';
import validButtonState from './validButton/reducer';

export default combineReducers({
  occurrence,
  configuration,
  feedBack,
  detailsOccurrence,
  lgpd,
  configurationSystem,
  endpointErrorSave,
  validButtonState
});
