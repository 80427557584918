import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 50px;
  padding: 10px 0px;
  margin: 30px 0px;
  display: flex;
  justify-content: center;

  > div {
    height: 100%;
    width: 80%;
    display: flex;
    justify-content: space-around; 
    padding-left: 4px;
    
  }
`;

export const DotsContent = styled.div `
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;

  > span:nth-child(2) {
    display: block;
    width: 20px;
    height: 20px;
    background: ${({ makerd }) =>  makerd ? '#ff7716' : '#adadad'};
    border-radius: 50%;
    margin-right: -4px;
    z-index: 9;
    position: relative;
  }

  > span:nth-child(1) {
    display: block;
    width: 20px;
    height: 5px;
    background: ${({ makerd }) =>  makerd ? '#ff7716' : '#adadad'};
    flex-grow: 1;
    margin-right: -4px;
  }

  &:first-child  {
    flex-grow: inherit !important;

    > span:nth-child(1) {
      display: none;
    }
  }
`