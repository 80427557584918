import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Container, DotsContent } from './styles';

function StepIndicator({ step, qtd }) {
  const [quantidade, setQuantidade] = useState([]);

  useEffect(() => {
    let newqtd = [];

    for(let i = 0; i < qtd; i++) {
      newqtd.push(i)
    }

    setQuantidade(newqtd)
  }, [qtd])

  return (
    <Container>
      <div>
        {quantidade.map((res, index) => {
          return (
            <DotsContent makerd={step > index }>
              <span></span>
              <span className={'data-cy-qrcode-step-' + (index+1)}></span>
            </DotsContent>
          )
        })}
      </div>
    </Container>
  );
}

StepIndicator.propTypes = {
  step: PropTypes.number,
  qtd: PropTypes.number
};

StepIndicator.defaultProps = {
  step: 1,
  qtd: 1
};

export default StepIndicator;