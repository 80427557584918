
export function setDetailsOccurrence(data) {
  return {
    type: '@detailsOccurrence/GET_DETAILS_OCCURRENCE',
    payload: { data },
  };
}

export function resetDetailsOccurrence(data) {
  return {
    type: '@detailsOccurrence/RESET_DETAILS_OCCURRENCE',
    payload: { data },
  };
}

export function setValidCell(data) {
  return {
    type: '@detailsOccurrence/SET_VALID_CELL',
    payload: { data },
  };
}
