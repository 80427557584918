import React, { useEffect ,useState } from 'react';

import PropTypes from 'prop-types';

import { useSelector } from 'react-redux'

import { Wrapper, Scroll } from './styles';

import Header from '../../../Components/Header'

export default function DefaultLayout({ children }) {
  const { background } = useSelector(state => state.configuration.data);
  const { show } = useSelector(state => state.feedBack);
  const heightDefault = useSelector(state => state.configurationSystem.height_default_of_layout_default);
  const [key, setKey] = useState(0)

  useEffect(() => {
    setKey(key === 0 ? 1: 0)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return (
    <Scroll 
      background={background}
      options={{ suppressScrollY: show }}
      key={key}
    >
      <Header />
      <Wrapper heightDefault={heightDefault}>
        {children}
      </Wrapper>
    </Scroll>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
