export function setEndpointError(data) {
  return {
    type: '@endpointError/SET_ENDPOINT_ERROR',
    payload: { data },
  };
}

export function resetEndpointError(data) {
  return {
    type: '@endpointError/RESET_ENDPOINT_ERROR',
    payload: { },
  };
}
