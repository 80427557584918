import styled from 'styled-components';

import { Link } from 'react-router-dom';

export const Container = styled.div`
  min-height: 100%;
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 20px;
  box-sizing: border-box !important;
  flex-direction: column;
  align-items: center;
`;

export const H4 = styled.h4`
  word-break: break-all;
`;

export const Content = styled.div`
  display: flex;
  height: 100%;
  background: #ffffffc9;
  border-radius: 4px;
  box-shadow: 0px 0px 4px 0px #00000024;
  flex-direction: column;
  padding: 10px 20px;
  /* justify-content: space-around; */
  width: 60%;

  > form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 80%;

    > div {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      > label {
        font-weight: 600;
        color: #333;
      }

      > input {
        margin-top: 10px;
        padding: 15px;
        border: none;
        border-radius: 50px;
        background: transparent;
        font-size: 16px;
        width: 100%;
        color: #333;
        font-weight: 500;
        background: #fff;

        > &::placeholder {
          color: #777 !important;
        }
        > &:invalid {
          color: gray;
        }
      } 

      > .select {
        display: flex;
        border-radius: 50px;
        background: #fff;
        padding-right: 0px;
        margin-top: 10px;

        select {
          padding: 15px;
          border: none;
          border-radius: 50px;
          background: transparent;
          font-size: 16px;
          width: 100%;
          color: #333;
          font-weight: 500;

          > &::placeholder {
            color: #777 !important;
          }
          > &:invalid {
            color: gray;
          }
        } 
      }
    }
  }

  @media only screen and (max-width: 800px) {
    width: 100%;
  }

  > * + * {
    margin-top: 8px;
  }

  > div.buttons-bottom-container {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    justify-content: space-between;

    > button, a {
      border: none;
      border-radius: 50px;
      padding: 16px 0px;
      font-size: 16px;
      background: linear-gradient(90deg, rgba(104,153,255,1) 35%, rgba(160,96,187,1) 100%); 
      color: #fff;
      opacity: 1;
      width: 45%;
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 51px;

      &:disabled {
        opacity: 0.6;
      }

      &:active {
        transition: 0.2s;
        opacity: 0.6;
      }
    }
  }
`;

export const CardRow = styled.div`
  display: flex;
  background: #fff;
  padding: 11px;
  border-radius: 3px;
  box-shadow: 0px 0px 5px 0px #9898983d;

  > div {
    display: flex;
    flex-direction: column;

    > * {
      font-weight: 300;
      color: #333;
    }
  }

  div:nth-child(1) {
    margin-right: 10px;
    padding-top: 4px;
  } 

  
`

export const CardRowButton = styled(Link)`
  display: flex;
  background: #ffffff26;
  padding: 11px;
  border-radius: 3px;
  box-shadow: 0px 0px 5px 0px #9898983d;
  border: none;
  cursor: pointer;

  > div {
    display: flex;
    flex-direction: column;
    align-items: start;

    > * {
      font-weight: 300;
      color: #333;
    }
  }

  div:nth-child(1) {
    margin-right: 10px;
    padding-top: 4px;
  } 

  &:active {
    transition: 0.1s;
    opacity: 0.5;
  } 
`

export const OpenStatus = styled.span`
  color: red;
  font-weight: normal;
`;

export const OngoingStatus = styled.span`
  color: #ffc107;
  font-weight: normal;
`;

export const CancelledStatus = styled.span`
  color: #dc3545;
  font-weight: normal;
`;

export const FinishedStatus = styled.span`
  color: #007bff;
  font-weight: normal;
`;

export const PendentStatus = styled.span`
  color: #6c757d;
  font-weight: normal;
`;

export const ForwardedStatus = styled.span`
  color: blue;
  font-weight: normal;
`;

export const RefusedStatus = styled.span`
  color: black;
  font-weight: normal;
`;

export const OtherStatus = styled.span`
  color: black;
  font-weight: normal;
`;

export const ReopenedStatus = styled.span`
  color: #c617c6;
  font-weight: normal;
`;

export const LoadTime = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;

  > .verde {
    background: #93eb91;
  }

  > .vermelho {
    background: #f20509;
  }
  
  > div.title {
    font-size: 12px;
  }

  > .time {
    background: #ccc;
  }

  > div#time, div.time {
    color: #fff;
    padding: 0px 10px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 24px;
    min-width: 116px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ContentErrorQrCode = styled.div`
  display: flex;
  width: 60%;
  height: 100%;
  border-radius: 4px;
  background: #ffffffc9;
  /* background: #ffffff8c; */
  box-shadow: 0px 0px 4px 0px #00000024;
  flex-direction: column;
  padding: 10px 20px;
  /* justify-content: center; */

  @media only screen and (max-width: 800px) {
    width: 100%;
  }

  > div:nth-child(1) {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  > div:nth-child(2) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-grow: 1; */
    flex-direction: column;
    margin-top: 6%;

    > h2 {
      text-align: center;
      
      > strong {
        text-align: center;
        color: #333;
      }
    }

    > p {
      padding: 12px 0px;
      font-size: 16px;
      color: #555;
      text-align: center;
    }
  }
`;


//////////////////////////////////////
//////////////////////////////////////
//////////////////////////////////////

export const ContainerExpiredCode = styled.div`
  height: 100%;
  display: grid;
  justify-content: space-around;
  width: 100%;
  /* padding-left: 12px;
  padding-right: 12px; */
  /* background: #fff; */
  grid-template-columns: 
    25% 
    50%
    25%;
  padding: 10px;

  box-sizing: border-box !important;

   @media only screen and (max-width: 800px) {
    display: grid;
    flex-direction: column;
    grid-template-columns: 2% 94% 2%;
  }
`;

export const ColumnExpiredCode = styled.div`
  display: flex;
  flex-grow: 1;
  /* height: 100%; */
  justify-content: center;
  align-content: center;
  align-items: center;

  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const ContentExpiredCode = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: #ffffffc9;
  /* background: #ffffff8c; */
  box-shadow: 0px 0px 4px 0px #00000024;
  flex-direction: column;
  padding: 10px 20px;
  /* justify-content: center; */

  > div:nth-child(1) {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  > div:nth-child(2) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-grow: 1; */
    flex-direction: column;
    margin-top: 15%;

    > h2 {
      text-align: center;
      
      > strong {
        text-align: center;
        color: #333;
      }
    }

    > p {
      padding: 12px 0px;
      font-size: 16px;
      color: #555;
      text-align: center;
    }
  }
`;
