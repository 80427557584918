import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import Loading from '~/Components/Loading'

function getTime(duration) {
  let seconds = ("0" + (Math.floor(duration / 1) % 60)).slice(-2);
  let minutes = ("0" + (Math.floor(duration / 60) % 60)).slice(-2);
  let hours = Math.floor(duration / 3600) > 9?Math.floor(duration / 3600):
  '0'+Math.floor(duration / 3600);

  let miliToTIme = hours + ':' + minutes + ':' + seconds

  return miliToTIme
}

function Stopwatch() {
  const { data } = useSelector(state => state.detailsOccurrence);
  const configuration = useSelector(state => state.configuration.data)

  const [containerClass, setContainerClass] = useState('verde');
  const [trigger, setTrigger] = useState(null);
  const [time, setTime] = useState('00:00:00')

  useEffect(() => {
    function init() {
      let resolutionTimeSplit = configuration.resolutionTime.split(':');
      let resolutionTime = parseInt(resolutionTimeSplit[0])*86400
      resolutionTime += parseInt(resolutionTimeSplit[1])*3600
      resolutionTime += parseInt(resolutionTimeSplit[2])*60
      resolutionTime += (parseInt(resolutionTimeSplit[3]))

      if(resolutionTime < data.attendanceTime){
        setContainerClass('vermelho')
      }

      let initalTime = data.attendanceTime

      // eslint-disable-next-line react-hooks/exhaustive-deps
      setTrigger(setInterval(() => {
        setTime(getTime(initalTime))
        initalTime = initalTime + 1

        if(resolutionTime < initalTime){
          setContainerClass('vermelho')
        }
      }, 1000));
    } 

    if(configuration.resolutionTime){
      init()  
    }
    
    return () => {
      setTrigger(clearInterval(trigger));
      // setTime('00:00:00')
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.attendanceTime]);

  return (
    <div id="time" className={containerClass}>
      {time === '00:00:00'?<Loading show={true} type="small"/> :time}
    </div>
  );
}

export default Stopwatch;
