import produce from 'immer';

const INITIAL_STATE = {
  data: {
    protocol: null,
    protocolAcessForDetails: '',
    validCell: false,
    name: "",
    title: "",
    description: "",
    motivo: "",
    status: "",
    dangerousness: "",
    history: [],
    images: [],
    data: null,
    localName: "",
    attendanceTime: 0,
    user_id: null,
  }
};

export default function detailsOccurrence(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@detailsOccurrence/GET_DETAILS_OCCURRENCE': {
        draft.data = action.payload.data;        
        break;
      }
      case '@detailsOccurrence/SET_VALID_CELL': {
        draft.data.validCell = action.payload.data;        
        break;
      }
      case '@detailsOccurrence/RESET_DETAILS_OCCURRENCE': {
        draft.data = INITIAL_STATE.data;        
        break;
      }
      default:
        return state;
    }
  });
}
