import styled from 'styled-components';

export const Container = styled.div`
  height: 20%;
  /* background: #b8e8ff none repeat scroll 0% 0%; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  > img {
    max-height: 100%;
    max-width: 100%;
    opacity: 0.7;
  }
`;
