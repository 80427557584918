import React from 'react';

import { Form, Input } from '@rocketseat/unform';

function Two({ setCodeValue }) {
  return (
    <Form onSubmit={() => {}}>
      <div>
        <label className="data-cy-qrcode-type-token-label">Por favor digite abaixo o token enviado por SMS para seu celular:</label>
        <Input
        className="data-cy-qrcode-token-input"
          type="text"
          name="code"
          inputMode="numeric"
          placeholder="código"
          autoComplete="off"
          maxLength={4}
          onChange={(e) => {
            setCodeValue(e.target.value)
          }}
        />
      </div>
    </Form>  
  );
}

export default Two;
