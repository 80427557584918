export function setValidation(data) {
  return {
    type: '@navigationButton/SET_VALIDATION', 
    payload: {data}
  }
}

export function resetValidation() {
  return {
    type: '@navigationButton/RESET_VALIDATION',     
  }
}