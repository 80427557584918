import React from 'react';

import { format, parseISO } from 'date-fns'

import { useSelector } from 'react-redux'

import { 
  Container, 
  Content, 
  Title, 
  Card,
  OpenStatus,
  OngoingStatus,
  CancelledStatus,
  FinishedStatus,
  PendentStatus,
  RefusedStatus,
  OtherStatus,
  ForwardedStatus,
  ReopenedStatus
} from "./styles";

function History({ history }) {
  const { history: list_history, protocolAcessForDetails, user_id } = useSelector(state => state.detailsOccurrence.data)

  function getStatus(data) {
    if (data.toUpperCase() === "OPEN") {
      return <OpenStatus>ABERTO</OpenStatus>;
    } else if (data.toUpperCase() === "ONGOING") {
      return <OngoingStatus>EM ANDAMENTO</OngoingStatus>;
    } else if (data.toUpperCase() === "CANCELLED") {
      return <CancelledStatus>CANCELADO</CancelledStatus>;
    } else if (data.toUpperCase() === "FINISHED") {
      return <FinishedStatus>FINALIZADO</FinishedStatus>;
    } else if (data.toUpperCase() === "PENDENT") {
      return <PendentStatus>PENDENTE</PendentStatus>;
    } else if (data.toUpperCase() === "REFUSED") {
      return <RefusedStatus>RECUSADO</RefusedStatus>;
    } else if (data.toUpperCase() === "CLOSED") {
      return <RefusedStatus>FECHADO</RefusedStatus>;
    } else if (data.toUpperCase() === "REOPENED") {
      return <ReopenedStatus>REABERTO</ReopenedStatus>;
    } else if (data.toUpperCase() === "FORWARDED") {
      return <ForwardedStatus>ENCAMINHADO</ForwardedStatus>;
    } else {
      return <OtherStatus>{data}</OtherStatus>;
    }
  }

  return (
    <Container>
       <Content>
          <Title className="data-cy-qrcode-history-title">
            Histórico
          </Title>
          <div className="content_history">
            {list_history.map((item, index) => {
              return (
                <Card
                  key={'item-'+index}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    paddingHorizontal: 15,
                    paddingVertical: 15,
                    marginBottom: 15,
                    backgroundColor: "#fff",
                    borderBottomColor: "#ccc",
                    borderBottomWidth: 1,
                    padding: 10
                  }}
                >
                  <div className={"data-cy-qrcode-history-date-" + item.status}
                    style={{ fontSize: 12, marginBottom: 5 }}>
                    {format(parseISO(item.createOn), "dd/MM/yyyy - HH:mm:ss")} -{" "}
                    {getStatus(item.status)}
                  </div>
                  <div className="data-cy-qrcode-history-description" style={{ fontSize: 16 }}>
                    <div style={{ fontSize: 14, fontWeight: "bold" }}>
                      {item.responsibleName}
                    </div>{" "}
                    - {item.description}
                  </div>
                </Card>
              );
            })}
          </div>
          {
            protocolAcessForDetails &&
            <div className="buttons-bottom-container">
              <button className="data-cy-qrcode-history-back-button" onClick={() => {
                history.push(`/details/${protocolAcessForDetails}`)
              }}>Voltar</button>
            </div>
          }
          
        </Content>
    </Container>
  );
}

export default History;
