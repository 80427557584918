import React from 'react';

import { Switch, BrowserRouter } from 'react-router-dom';

import Route from './routes';

import Home from '~/pages/Home';

import Occurrence from '~/pages/FeaturesOccurrence/create';

import Details from '~/pages/FeaturesOccurrence/Details';

import History from '~/pages/FeaturesOccurrence/History';

import Evidence from '~/pages/FeaturesOccurrence/Evidence';

import Tasksperformed from '~/pages/TasksPerformed/List';

import TasksperformedDetails from '~/pages/TasksPerformed/Details';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact initial component={Home} />
        <Route path="/occurrence" isPrivate component={Occurrence} />
        <Route path="/details/:code" exact component={Details} />
        <Route path="/history" isPrivate component={History} />
        <Route path="/evidence" isPrivate component={Evidence} />
        <Route path="/tasksperformed" exact component={Tasksperformed} />
        <Route path="/tasksperformed/details" component={TasksperformedDetails} />
      </Switch>
    </BrowserRouter>
  );
}
