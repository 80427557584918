import styled from 'styled-components'

export const Container = styled.div`
align-items: center;
display: flex;
flex-direction: column;
position: fixed;
z-index: 10;
width: 100%;
top: 0;
left: 0;
padding: 20px;
z-index: 11;
min-height: 100%;
height: 100%;
background: #0000009e;
display: flex;
justify-content: center;


@media only screen and (max-width: 800px) {
  height: auto;
}
`;

export const Content = styled.div`
display: flex;
flex-direction: column;
justify-content: space-around;
align-items: center;
height: ${(props) => props.height ? props.height : '100%'}; 
background: #ffffffe8;
width: ${(props) => props.width ? props.width : '60%'}; 
border-radius: 4px;
padding: 20px 0px;
padding-top: 0px;
position: relative;

.message {
  width: 100%;
} 

> button {
  position: absolute;
  top: 12px;
  right: 12px;
  background: transparent;
  border: none;
}

.header {
  color: rgb(160 96 187);
  font-size: 20px;
  font-weight: 600;
  padding: 20px 0px;
  border-bottom: 1px solid;
  width: 96%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  border-color: #ddd;
}

> div.buttons-bottom-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0px 40px;

  > .checkbox {
    padding: 40px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ;

    > input {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  > button {
    border: none;
    border-radius: 50px;
    padding: 16px 0px;
    font-size: 16px;
    background: linear-gradient(90deg, rgba(104,153,255,1) 35%, rgba(160,96,187,1) 100%); 
    color: #fff;
    opacity: 1;
    width: 100%;

    &:disabled {
      opacity: 0.6;
    }

    &:active {
      transition: 0.2s;
      opacity: 0.6;
    }
  }

  > button + button {
    margin-top: 10px;
  }
}
`;

