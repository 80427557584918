import React from 'react';

import { useSelector } from 'react-redux'

import PropTypes from 'prop-types';

import { Wrapper, Scroll } from './styles';

export default function DefaultLayout({ children }) {
  const { background } = useSelector(state => state.configuration.data);

  return (
    <Scroll background={background}>
      <Wrapper>
        {children}
      </Wrapper>
    </Scroll>
  );
} 

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
