import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100%;
  display: flex;
  width: 100%;
  padding: 10px;
  box-sizing: border-box !important;
  justify-content: center;
  
`;

export const Content = styled.div`
  display: flex;
  min-height: 100%;
  background: #ffffffc9;
  border-radius: 4px;
  box-shadow: 0px 0px 4px 0px #00000024;
  flex-direction: column;
  padding: 10px 10px;
  justify-content: space-between;
  width: 60%;

  @media only screen and (max-width: 800px) {
    width: 100%;
  }

  > .content {
    height: 100%;
    display: flex;
    flex-direction: column;

  
    > div:nth-child(1) {
      padding: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 16px;
      color: #4fafbd;
      text-align: center;
    }
  
    > div:nth-child(2) {
      padding: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 18px;
      color: #222;
  
      > span {
        margin-left: 10px;
      }
    }
  }


  > div.bottom-container {
        
    > div:nth-child(1) {
      padding: 12px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      color: #222;
      
      > div:nth-child(1) {
        display: flex;
        text-transform: uppercase;
        width: 68%;
  
        > span {
          display: block;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
  
        > svg {
          margin-right: 6px;
        }
      }
  
      > div:nth-child(2) {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
  
        > svg {
          margin-right: 6px;
        }
      }
    }

    > .button {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      justify-content: space-between;
      padding: 0px 0px;
  
      > button, a {
        border: none;
        border-radius: 50px;
        padding: 16px 0px;
        font-size: 16px;
        background: linear-gradient(90deg, rgba(104,153,255,1) 35%, rgba(160,96,187,1) 100%); 
        color: #fff;
        opacity: 1;
        width: 100%;
        text-align: center;
  
        &:disabled {
          opacity: 0.6;
        }
  
        &:active {
          transition: 0.2s;
          opacity: 0.6;
        }
      }
    } 
  }
`;

export const CardContent = styled.div`
  padding: 12px;
  flex-grow: 1;
  background: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  > div + div {
    margin-top: 12px;
  }

  > .teste {
    width: 100%;
  }
`;

export const CardSuccess = styled.div`
  box-shadow: 0px 1px 4px 1px #00000033;
  display: flex;
  background: #fff;
  border-radius: 4px;
  width: 100%;
  padding: 12px;

  > div:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
  }

  > div:nth-child(2) {
    font-weight: 600;
    font-size: 14px;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #222;
  }
`;

export const CardError = styled.div`
  box-shadow: 0px 1px 4px 1px #00000033;
  display: flex;
  background: #fff;
  border-radius: 4px;
  padding: 12px;
  width: 100%;
  flex-direction: row;

  > div:nth-child(1) {
    display: flex;
    padding: 3px;
    color: #222;
  }

  > div:nth-child(2) {
    padding: 3px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    > span:nth-child(1) {
      font-weight: 600;
      font-size: 14px;
      text-transform: uppercase;
      color: #222;
    }

    > span:nth-child(2) {
      font-size: 14px;
      color: #b11a1a;
    }
  }
`;