import React, { useEffect } from 'react';

import { toast } from 'react-toastify'

import PropTypes from 'prop-types'

import InputMask from 'react-input-mask';

const InputPhone = ({
  data,
  setData,
  setValidPhone,
  validPhone,
}) => {

  function apenasNumeros(string) {
    var numsStr = string.replace(/[^0-9]/g,'');
    return parseInt(numsStr);
  }

  useEffect(() => {
    if (data.data.phone.length < 2) {
      setValidPhone(true);
      setData({
        ...data.data,
        validPhone: false
      });
    } else if(data.data.phone.length > 2 
      && data.data.phone[1] !== '5'
      && data.data.phone[2] !== '5') {
      setValidPhone(false);
      setData({
        ...data.data,
        validPhone: false
      });
    } else if(data.data.phone.length > 5 && data.data.phone[9] !== '9') {
      setValidPhone(false);
      setData({
        ...data.data,
        validPhone: false
      });
    } else {
      const newValue = "+" + apenasNumeros(data.data.phone)
      setValidPhone(!(newValue.length < 14));
      setData({
        ...data.data,
        validPhone: !(newValue.length < 14)
      });
    }   
        
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.data.phone]) 

  return (
    <>
      <InputMask
        mask="+55 (99) 99999-9999"
        placeholder="+55 (99) 99999-9999"
        value={data.data.phone}
        defaultValue={data.data.phone}
        inputmode="numeric"
        name="phone"
        onChange={value => {
          setData({
            ...data.data,
            phone: value.target.value,
          });
        }}
        onBlur={(e) => {       
          
          if(!e.target.value){
            setValidPhone(true)      
            toast.error('Número de telefone inválido!') 
          }else{
            setValidPhone(false)      
          }
        }}
      />
      {!validPhone && <span className='error'>Telefone Inválido</span>}
    </>
  );
};

InputPhone.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
  setValidPhone: PropTypes.func,
  validPhone: PropTypes.bool
};

InputPhone.defaultProps = {
  data: {
    data: {
      phone: ''
    }
  },
  setStep: () => {},
  setValidPhone: () => {},
  validPhone: true
};

export default InputPhone;
